$primary_theme_color: #ffffff;

$primary_background_color: #FFF;
$secondary_background_color: #ffffff;
$third_background_color: #222A39;
$overlay-backdrop: #6a6868e0;

$text-color--primary: #333333;
$primary_font: 'Inter', sans-serif;




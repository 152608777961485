//reun-user
#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-left.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog {
    background: #3db8eb1a  !important;
    backdrop-filter: blur(5px) !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-left.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog:after {
    border-top-color: #3db8eb1a !important;
    top: 100.5% !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body h1 {
    color: #212a39  !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body .divider {
    border-bottom: 1px solid #bababa  !important;
    margin: 0.25em -0.6em !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body .instructions {
    color: #212a39 !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher .onesignal-bell-launcher-dialog button#unsubscribe-button {
    background: #f93b40 !important;
    color: #fafafa !important; 
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher .onesignal-bell-launcher-dialog button#subscribe-button {
    background: #3DB8ED !important;
    color: #fafafa !important; 
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher .onesignal-bell-launcher-dialog button.action:hover {
    background: unset !important;
} 

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-left.onesignal-bell-launcher-md {
    bottom: 90px;
    left: 20px;

    @media screen and (min-width: 429px) {
        bottom: 30px;
        left: 30px;
    }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-message .onesignal-bell-launcher-message-body {
    font-size: 12px !important;

    @media screen and (min-width: 429px) {
        font-size: 14px !important;
    }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-left.onesignal-bell-launcher-sm {
    bottom: 90px;
    left: 20px;

    @media screen and (min-width: 429px) {
        bottom: 30px;
        left: 30px;
    }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body .push-notification {
    background: #c1c2c5 !important;
}
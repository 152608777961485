@import "abstracts/variables";

@import "base/animations";
@import "base/font";
@import "base/base";

@import "component/element";
@import "component/notification";

.react-pdf__Document{
    width: 100%;
    height: 100%;
    .react-pdf__message{
        font-size: 1rem;
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

.row{
    margin: 0;
}

.container{
    font-size: 1.6rem;
}

.setting-forum-modal{
    z-index: 9999;
    position: relative;
}

.content-container{
    padding-bottom: 5em !important;
    font-family: 'Inter';
    min-height: 100vh;
    @media screen and (min-width: 991px) {
        margin-left: 200px;
        padding: 1px 16px;        
    }
}

// ---- Header Container ---- //
.main-header-container{
    background-color: transparent;
    text-align: center;
    z-index: 5;
    // &.--desktop{
    //     padding-top: 2em;
    //     padding-bottom: 2em;
    //     ._label{
    //         &.--app_name{
    //             position: relative;
    //             z-index: 5;
    //             font-size: 2.5rem;
    //             color: #FAFAFA;

    //         }
    //         &.--nav-item{
    //             padding: 0 1em;
    //             font-size: 1.6rem;
    //             width: auto;
    //             color: #FAFAFA;
    //             position: relative;
    //             z-index: 5;
    //         }
    //     }
    //     .section-right{
    //         align-items: center;
    //         justify-content: end;
    //         display: flex;
    //         padding: 0 5em;
    //     }
    // }
    &.--mobile{        
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        h1{
            z-index: 6;
            margin-left: 0.5em;
            font-size: 2rem;
            color: #FAFAFA;
        }
        img{
            z-index: 5;
        }
    }
}

.info-header-container{
    background-color: $primary_theme_color;
    border-bottom: 0.1em solid #AFACAC;
    padding: 2em 0em 1.25em 0em;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    margin: 0 !important;
    right: 0;
    z-index: 1100;
    .info-row{
        display: flex;
        align-items: flex-end;
        .info-col{
            text-align: center;
            label{
                font-family: 'inter-regular';
                font-size: 1.6rem;
            }
        }
    }

    @media (min-width: 991px) {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
}

// ---- Navbar Container ---- //
.main-navbar-container{
    background-color: $secondary_background_color;
    padding: 1em 0 1em 0;
    border-top: 0.1em solid #AFACAC;
    .row{
        .col{
            display: grid;
            place-items: center;
        }
    }
}

.side-navbar-container{
    margin: 0;
    padding: 0;
    width: 200px;
    background-color: $primary_background_color;
    position: fixed;
    height: 100%;
    overflow: auto;
    .logo-icon{
        width: 15em;
        height: 3.5em;
    }
    .row:first-child{
        margin-top: 5em;
        margin-left: 0;
        margin-right: 0;
    }
    .tab-bar{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #222A39;
        font-size: 2rem;
        margin-top: 0.5em;
        &.--active{
            width: 7em;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1.7rem;
            background: #EBEBEB;
            color: #222A39;
            font-size: 2rem;
        }
        img{
            margin-right: 0.5em;
        }
        .navbar-label{
            width: 3.5em;
            font-size: 20px;
            line-height: unset;
            margin: 0;
        }
    }
    .button-group.--notification-button.--create-post {
        width: 5.8em;
        box-shadow: none !important;
        border-radius: 1.7rem !important;
        margin: 0.5em 0 0 0;
    }
}

// ---- Common  Container ---- //
.notify-div{
    padding: 0.5em;
    background: #F4FBFF;
    border-radius: 0.8em;
    ._label{
        color: #333333;
        font-size: 1.2rem;
        line-height: unset;
    }
    .icon{
        width: 100%;
    }
}

// ---- Modal Order Container ---- //
.modal-backdrop{
    opacity: 1 !important;
    background-color: $overlay-backdrop !important;
}

.modal-container{
    padding: 0 !important;
    width: 100%;

    .modal-dialog {
        @media (max-width: 991px) {
            max-width: none !important;
            margin: 0 !important;
        }
    }

    .modal-content{
        border-radius: 2em;
        @media (max-width: 991px) {
            border-radius: 0em;
        }
    }
    .modal-body{
        @media (max-width: 991px) {
            margin-top: 7em !important;
        }
        padding: 0;
    }
    .swiper{
        margin: 0 !important;
        .swiper-slide{
            width: 100% !important;
        }
    }
    .image-div{
        display: flex;
        justify-content: center;
        .logo-image{
         margin-top: 12.5em;
          width: 168px;
          height: 168px;
          opacity: 0.3;
        }
    }
    .order-div{
        margin-top: 3em;
        margin-bottom: 5em;
        padding: 0em 2em;
        min-height: 100%;
        &.desktop{
            margin: 1em 0em 5em !important;
        }
        .tag-row{
            margin-top: 2.5em;
            margin-bottom: 2em;
            .tag{
                ._label{
                    color: #333333;
                    font-size: 1.8rem;
                    line-height: 21.78px;
                }
            }
        }
        &.mission{
        margin-top: 8em;
        }
        &.activity_log{
                &.desktop{
                    margin: 0em !important;
                }
                padding: 0em 2em;
                padding-bottom: 5em;
                .date-title{
                    padding: 1em 0.5em 0.5em;
                    font-size: 1.8rem;
                    color: #333;
                    font-family: 'Inter';
                    font-weight: 600 !important;
                    top: 4em;
                    z-index: 3;
                    background: #FFF;
                    @media screen and (max-width: 991px) {
                        padding-top: 0.5em;
                        top: 3.7em;
                    }
                }
                .event-row{
                    padding: 1em 0;
                    .avatar-div{
                        width: auto;
                        position: relative;
                        height: 5em;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        .action-icon{
                            width: 2em;
                            height: 2em;
                            position: absolute;
                            bottom: -0.5em;
                            right: 0.5em;
                            z-index: 2;
                        }
                        .avatar{
                            object-fit: cover;
                            border-radius: 50%;
                            height: 5em;
                        }
                        .react-pdf__Document{
                            width: 50px !important;
                            height: 50px !important;
                            object-fit: cover;
                            object-position: center;
                            border-radius: 50%;
                            overflow: hidden;
                            display: flex;
                            align-items: center;
                            .react-pdf__Page{
                                .react-pdf_Page_canvas{
                                    width: 50px !important;
                                    height: 50px !important;
                                    object-fit: cover;
                                    object-position: center;
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                    .event-detail-div{
                        .action-text{
                            line-height: unset;
                            font-size: 1.2rem;
                            margin: 0;
                            color: #4f4f4f;
                            font-weight: unset !important;
                        }
                    }
                    .event-content{
                        color: #4f4f4f !important;
                        font-size: 1rem;
                        color: #a8a8a8;
                        white-space: pre-line;
                        line-height: 1em;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        padding-bottom: 0.1em;
                    }
                }
                .event-see-more{
                    margin-bottom: 4em;
                    text-align: center;
                    font-size: 1.6rem;
                }
        }
       .info-row{
        text-align:center ;
            ._label{
                &.--remark{
                    font-size: 1.5rem;
                    font-weight: 600 !important;
                    line-height: unset !important;
                }
            }
       }
       .line{
        width: 100%;
        border: 1px solid rgba(83, 173, 240, 0.2);
       }
       .amount-row{
        margin-top: 2em;
        padding: 1em;
        border: 2px solid #ddeffc;
        border-radius: 8px;
        ._label{
            &.--total{
                font-size: 1.7rem;
                font-weight: 600 !important;
                line-height: unset;
            }
            &.--total-gained{
                font-size: 1.7rem;
                line-height: unset !important;
                margin-bottom: 0 !important;
            }
            &.--amount{
                font-size: 1.7rem;
                font-weight: 600 !important;
                color: #3db8eb;
                line-height: unset;
            }
        }
       }
       .payment-row{
        margin-top: 1.7em;
        margin-bottom: 1.5em;
        ._label{
            &.--title{
                font-size: 1.2rem;
                overflow-wrap: break-word;
                line-height: unset;
                margin-bottom: 0em !important;
            }
            &.--text{
                font-size: 1.4rem;
                font-weight: 600;
                line-height: unset;
                margin-bottom: 0em !important;
            }
            &.--total{
                font-size: 1.3rem;
                font-weight: 600;
                color: #3DB8ED;
            }
            &.--total_text{
                font-size: 1.3rem;
                font-weight: 600;
            }
            &.--detail{
                font-size: 1.1rem;
                color: #3DB8ED;
                text-decoration: underline;
            }
        }
          span{
                font-size: 1.4rem;
                font-weight: 600;
                }
       }
       .contact-row{
        margin-top: 1em;
        p{
            font-size: 1.2rem;
        }
        span{
        color: #577DE5;
        text-decoration: underline;
        }
       }
    }
    .order-card-container{
        padding: 1em;
        border-radius: 0.5em; 
        width:auto !important;
        background: #222A39;
        margin: 0.5em 0em !important;
        border: 1px solid #E5E5E5;
        .card-display{
            overflow: hidden;
            transition: height 0.5s;
              &.active {
                transition: height 0.5s;
              }
        } 
        .open-btn {
            display: flex;
            justify-content: center;
            margin-top: 0.5em;
            margin-bottom: 0em;

            div {
                position: absolute;
                margin-top: -0.6em;
                margin-left: -0.6em;
                .normal-animation {
                    animation: keyframes-return .5s;
                    transform-origin: center;
                    transform-box: fill-box;
                }
            }

            &.--opened {
                div {
                    .rotated-animation {
                        animation: keyframes-rotate .5s;
                        transform: rotate(180deg);
                    }
                }
            }
        }
        
        @keyframes keyframes-rotate {
            0% {
                transform: rotate(0deg);
                opacity: 0;
            }
            
            100% {
                transform: rotate(-180deg);
            }
        }
        
        @keyframes keyframes-return {
            0% {
                transform: rotate(-180deg);
                opacity: 0;
            }
            
            100% {
                transform: rotate(0deg);
            }
        }
        ._label{
            &.--order_no{
                color: #EEEEEE;
                font-size: 1.4rem;
                font-weight: unset;
                line-height: unset;
            }
            &.--form{
                color: #EEEEEE;
                font-size: 1.2rem;
            }
            &.--address{
                color: #EEEEEE;
                font-size: 1.2rem;
            }
            &.--user{
                color: #EEEEEE;
                font-size: 1.2rem;
            }
            &.--date{
                color: #EEEEEE;
                font-size: 1.2rem;
                text-align: left;
            }
            &.--status{
                color: #EEEEEE;
                font-size: 1.4rem;
                text-align: left;
            }
        }
        .line{
            width: 100%;
            border: 1px solid rgba(83, 173, 240, 0.2);
        }
        .step-container{
            .step-row{
                margin-top: 0.7em;
                align-items: center;
                .receive-btn{
                    color: #222A39;
                    font-weight: 500;
                    font-size: 13px;
                    background: #FAFAFA;
                    box-shadow: 3px 3px 1px 0px #AEC1CF;
                    border-radius: 8px;
                    padding: 6px 10px;
                    text-transform: capitalize;
                }
            }
        }
    }    
    .point-container{
        margin-top: 1.5em;
        border-radius: 0.8em;
        background:#222A39 ;
        text-align: center;
        .point-row{
            width: 90%;
            margin: auto;
            border-bottom: 1px solid #EDEDED;
        }
        ._label{
            &.--name{
                margin-top: 1.4rem;
                color: #FAFAFA;    
                font-size: 1.4rem;
            }
            &.--point{
                color: #FAFAFA;    
                font-size: 2.4rem;
                margin-top: 0.6em;
                margin-bottom: 0.6em !important;

                line-height: unset;
            }
        }
    }
    .point-history-header{
        align-items: center;
        margin-top: 2em;
        .tag{
        }
        ._label{
            &.--listing{
                font-size: 2rem;
                color: #303345;
                line-height: unset;
            }
            &.--text{
                display: flex;
                color: #666D76;
                font-size: 1.2rem;
                justify-content: end;
            }
        }
    }
    .recods-row{
        height: 5.5em;
        align-items: center;
        border-bottom: 0.05em solid #BBB6B6 ;
        ._label{
            &.--name{
                font-size: 1.3rem;
                color: #3A2E2E;
                line-height: unset;
                font-weight: unset;
            }
            &.--description{
                font-size: 1rem;
                color: #3A2E2E;
            }
            &.--time{
                font-size: 1rem;
                color: #3A2E2E;
                line-height: normal

            }
            &.--point{
                font-size: 1.4rem;
                text-align: end;
                color: #3A2E2E;
                line-height: unset;
                font-weight: unset;
            }
            &.--point2{
                font-size: 1.4rem;
                text-align: end;
                color : #3DB8ED;
                line-height: unset;
                font-weight: unset;
            }
        }
    }
    .event-card{
        border-radius: 1.5em;
        background:#FFFFFF ;
        border: 3px solid rgba(163, 163, 163, 0.1);
        margin: 1em 0em;
        ._label{
            &.--title{
                font-size: 1.4rem;
                color: $text-color--primary;
            }
            &.--date{
                display: flex;
                align-items: center;
                font-size: 1.1rem;
                color: #222A39;
                justify-content: start;
            }
            &.--status{
                font-size: 1.4rem;
                color: #FAFAFA;               
            }
            &.detail{
                font-size: 1.4rem;
                color: $text-color--primary;
            }
        }
        ._p{
            &.--description{
                font-size: 1.1rem;
                color: #5C5C5C;
                white-space: pre-line;
            }
            &.--description2{
                font-size: 1.1rem;
                color: $text-color--primary;
                white-space: pre-line;
            }
        }
        .title-row{
            padding: 0.7em 0em;
            align-items: center;
            border-bottom: 1px solid #EDEDED;
        }
        .ticket-row{
            margin-top: 1em;
            .image-col{
                height: 11.25em;
                .event-image{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .date-row{
            margin-top: 1em;
            align-items: center;
        }
        .status-row{
            margin-top: 0.5em;
            padding: 0px 1em;
            align-items: center;
            .status-div{
                padding: 0.6em;
                text-align: center;
                border-radius: 0.45em;
                background: $third_background_color;
                &.active{
                background: #3DB8ED;
                    box-shadow: 0px 4px 0px 0px #4797D1;
                }

                h4 {
                    font-weight: unset;
                    line-height: unset;
                    margin: 0;
                }
            }
        }
        .detail-div{
           margin-top: 1em;
           margin-bottom: 0.5em;
           background:#FAFAFA ;
           border: 1px solid #EDEDED;  
           border-radius: 0.5em;
           .detail-row{
            padding: 0.8em;
            ._label {
                &.--detail {
                    color: #333333;
                    font-size: 16px;
                }
            }
           }
           .detail-data{
            padding: 1em;
            border-top: 1px solid #EDEDED;  
           .attendee-row{
            p {
                border-radius: 0.5em;
                align-items: center;
                margin: 0em -0.5em 0.5em 0em !important;
                font-size: 1.2rem;
                ._span{
                    font-weight: 600;
                }
            }
           }

           }
           .ticket-detail{
            padding: 1em 1em 0em;
            border-top: 1px solid #EDEDED;  
            p {
                border-radius: 0.5em;
                align-items: center;
                margin: 0em -0.5em 0.5em 0em !important;
                font-size: 1.2rem;
                ._span{
                    font-weight: 600;
                }
            }
           }
        }
    }
    .event-see-more{
        margin-top: 1em;
        margin-bottom: 2em;
        text-align: center;
        font-size: 1.6rem;
    }
    .avatar-header{
        align-items: center;
        margin-bottom: 1em;
        ._label{
            color:  #333333;
            font-size: 2rem;
        }
    }
    .avatar-col{
        margin: 0.5em 0em;
        display: flex;
        justify-content: center;
        .avatar-div{
            height: 7em;
            width: 7em;
            align-items: center;
            border-radius: 50%;
            position: relative;
            display: flex;
            .avatar-image{
                padding: 0;
                border-radius: 50%;
                border-radius: inherit;
                height: 100%;
                width: 100%;
                object-fit: cover;
                &.active{
                  border: 4px solid transparent;   
                animation: rotateBorderAnimation 1s linear infinite;
                }
            }
            .lock-icon{
                height: 7em;
                width: 7em;
                left: 0;
                border-radius: 50%;
                color: black;
                position: absolute;
                background: rgba(0, 0, 0, 0.25);

                svg {
                    top: 50%;
                    left: 50%;
                    color: #e9e9e9a1;
                    position: absolute;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
  
    .mission-div{
        margin-top: 1em;
        padding-bottom: 1em;
        border-bottom: 1px solid rgba(83, 173, 240, 0.20);
        &:last-child{
            border-bottom: none;
        }
        &.--locked{
            position: relative;
            opacity: 0.5;
        }

        .items-row{
            margin: 0;
            height: 3.5em;
            justify-content: center;
            position: relative;
            .target-label{
                position: absolute;
                bottom: 0em;
                left: 50%;
                color: #C5C5C5;
                font-size: 1.4rem;
                z-index: 21;
                font-weight: 700;
                width: auto;
                transform: translate(-50%, 0);
            }
            ._label{
                &.--name{
                    color: #5C5C5C;
                    font-size: 1.6rem;
                }
            } 
            .image-col{
                display: flex;
                align-items: center;
                justify-content: center;
                .image-div{
                         width: 3.5em;
                         height: 3em;
                       .mission-image{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                         border-radius: 0.5em;
                      //    overflow: hidden;
                      //    border: 1px solid black;
                       }  
                   }
            }
            .task-col{
            border-radius: 1em;
            text-align: center;
            background: #EEEEEE;
            // position: relative;
            overflow: hidden;
            }
            .task-col.active {
                color: #222A39;
                background: linear-gradient(to right, #EEEEEE 0%, #222A39 100%);
                background-size: 200% auto;
                animation: slideBackground 1s linear forwards;
            }
            .absolute-container {
                position: absolute;
                bottom: -0.28em;
                right: 1em;
                z-index: 20;
                width: 2.2em;
                height: 2.2em;
                border-radius: 50%;
                overflow: hidden;
                border: 1px solid black;
                padding: 0;
                // object-fit: cover;
            }

            .shine {
                position: absolute;
                overflow: hidden;
                width: 50px;
                height: 50px;
                display: inline-block;
                border-radius: 5px;
                color: #fff;
                text-decoration: none;
                text-align: center;
                line-height: 50px;
                font-size: 12px;
                font-family: sans-serif;
                // background: #9c8d9c;
                z-index: 99;
            
                &::after {
                    content: "";
                    position: absolute;
                    top: -110%;
                    left: -210%;
                    width: 200%;
                    height: 200%;
                    opacity: 0;
                    transform: rotate(30deg);
                    
                    // background: rgba(255, 255, 255, 0.13);
                    background: linear-gradient(
                        to right, 
                        rgba(255, 255, 255, 0.13) 0%,
                        rgba(255, 255, 255, 0.13) 77%,
                        rgba(255, 255, 255, 0.5) 92%,
                        rgba(255, 255, 255, 0.0) 100%
                    );
                    animation: shineEffect 1.5s ease infinite;
                }
            }
            
            @keyframes shineEffect {
                0% {
                    opacity: 0;
                    top: -110%;
                    left: -210%;
                }
                50% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                    top: -50;
                    left: 0;
                }
            }

            .bonus-image {
                // position: absolute;
                // bottom: -0.28em;
                // right: 1em;
                // z-index: 20;
                width: 100%;
                height: 100%;
                // border-radius: 50%;
                // overflow: hidden;
                // border: 1px solid black;
                object-fit: cover;
                
                // Your existing styles...
            } 
        }
    }
}

//car_page
// .redeem-history-container{
//     height: 76vh;
//     overflow: scroll;
//     .label-row{
//         margin: 0em 1em;
//         margin-top: 1em  !important;
//         ._label{
//             &.--label{
//                 color:#282525;
//                 font-size: 2.5rem;
//             }
//         }
//      }
//     .redeem-card-container{
//                 padding: 0.6em;
//                 border-radius: 0.5em; 
//                 width:auto !important;
//                 background: #FFFCFC;
//                 margin: 0.3em 1em !important;
//                 border: 1px solid #E5E5E5;
//             ._label{
//                 &.--text{
//                     color: #828892;
//                     font-size: 1.4rem;
//                 }
//                 &.--last_balance{
//                     color: #868686;
//                     font-size: 1.2rem;
//                 }
//             }
//         }
//     .point-card-container{
//             padding: 0.6em;
//             border-radius: 0.5em; 
//             width:auto !important;
//             background: #FFFCFC;
//             margin: 0.3em 1em !important;
//             border: 1px solid #E5E5E5;
//         ._label{
//             &.--text{
//                 color: #828892;
//                 font-size: 1.4rem;
//             }
//             &.--receive{
//                 color: #868686;
//                 font-size: 1.2rem;
//             }
//             ._span{
//                 color: #282525;
//             }
//         }
//     }
// }
// .redeem-history-container::-webkit-scrollbar{
//     display: none !important;
// }

//forum_listing
// ---- Pages Home Container ---- //
.home-title-row{
    margin: 0;
    align-items: center;
    justify-content: space-between;

    margin: 0.3em 0em 1.3em !important;
    &.--car-inspection{
        margin-left: 0;
        margin-right: 0;
        margin-top: 4em !important;
        padding: 0em 2em;
        justify-content: unset;
    }
    h2{
        width: auto;
        font-size: 2.2rem;
    }
    a{
        width: auto;
    }
}

.home-container{
    overflow: hidden;
    padding: 0.5em;

    @media (min-width: 991px) {
        padding-bottom: 100px;
    }

    ._label{
        &.--banner-title{
            width: 100%;
            font-size: 2.3rem;
        }
    }
    .forum-pin-post-container{
        padding-top: 5em;
        color: #F4FBFF;
        z-index: 0;
        line-height: 1.6em;
        margin: 0;
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        background-image: url('../assets/images/RU-CNY-2.jpg');
        background-size: cover;
        background-position: center;

        @media screen and (min-width: 991px) {
            background-image: url('../assets/images/RU-CNY-1.jpg');
            background-size: cover;
            background-position: center;

            padding: 1em 1em 0em 1em!important;
            position: unset;
            color: #F4FBFF;
            background-color: #222A39;
            border-radius: 2.4rem;
            line-height: 1.6em;
            margin: 0;
            width: 100%;
        }
        ._label{
            &.--title{
                color: #333333;
                line-height: 1.2em;
                font-size: 16px;
            }
            &.--detail{
                padding-top: 0.5em;
                padding-left: 0.5em;
                padding-right: 0.5em;
                line-height: 1.1em;
                font-size: 1.2rem;
                color: #5C5C5C;
            }
            &.--like-detail{
                font-size: 1.2rem;
                color: #868686;
            }
        }
        .pin-details{
            margin: 0;
            position: relative;
            padding: 0.5em 0.5em 1em;
            font-family: $primary_font;
            font-weight: 600;
            height: 6em;

            @media (min-width: 991px) {
                height: 4em
            }
        }
        .swiper{
            margin-left: 0.5em;
            padding-bottom: 1.5em;
        }
        .swiper-slide-active{
            .post-pinned-card{
                opacity: 1 !important;
            }
        }
        .swiper-slide{
            .post-pinned-card{
                height: 7.5em;
                padding: 0.7em 0.8em;
                background-color: #FFFFFF;
                border-radius: 0.5em;
                opacity: 1;
                display: flex;
                section{
                    position: absolute;
                    bottom: 0.2em;
                    right: 0.8em;
                }
  
                &.--loading {
                    padding: 0 !important;
                    opacity: 1 !important;

                    .pinned-post-skeleton {
                        background-color: #dfdfdf;
                        position: relative !important;
                        right: 0 !important;
                        bottom: 0 !important;
                    }
                }
            }
        }
    }
   
    .event-carousell-container{
        .swiper{
        }
        .home-title-row{
            display: flex;
            justify-content: space-between;
            margin: 0.2em 0em 1.3em;
            padding-top: 2em;
        }
       .event-card{
            width: 8.75em !important;
            height: 12.25em !important;
            &.desktop{
                width: 10.625em !important;
                height: 14.875em !important;
            }

        .event-skeleton {
            background-color: #dfdfdf;
        }
        .event-image{
            // height: 100%;
            // width: 100%;
            // display: flex;
            // align-items: end;
            // object-fit: cover;
            // border-radius: 0.9em;
            // justify-content: center;
            // padding-bottom: 1.5em;

            position: relative !important;

            height: 100%;
            width: 100%;
            border-radius: 0.9em;
            padding: 0;
            display: flex !important;

            .event-image-frame {
                width: 100%;
                height: 100%;
                border-radius: 0.9em;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 0.9em;
                }
            }

            .label-row{
                gap: 10px;
                padding: 8px 18px;
                border-radius: 20px;
                display: inline-flex;
                align-items: flex-start;
                backdrop-filter: blur(4px);
                background: rgba(34, 42, 57, 0.20);

                left: 48%;
                bottom: 5%;
                position: absolute;
                z-index: 99999;
                transform: translate(-39%, -50%);

                @media (max-width: 991px) {
                    left: 35%;
                    transform: translate(-23%, -50%);
                }

                ._label{
                    color: #f5f5f5;
                    font-size: 1.2rem;
                    line-height: 14.06px;
                    text-transform: uppercase;
                }
            }
        }
       }
    }
    
    .partner-container {
        margin-top: 2.8em;
    
        @media screen and (min-width: 991px) {
            margin-top: 2.3em;
        }
    }

    .parallax {
    overflow: hidden;
    letter-spacing: -2px;
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    }
      
    .parallax .scroller {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 64px;
    display: flex;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    }
      
    .parallax article {
    display: flex;
    gap: 1em;

    .parallax-div{
        width: 200px;
        img{
            padding: 0.9em 0.2em;
            width: 100%; 
            height: 100%;
            object-fit: contain;

            @media (max-width: 991px) {
                padding: 0.6em 0.2em; 
            }
        }

        @media (max-width: 991px) {
            width: 120px;   
        }
    }

    @media (max-width: 991px) {
        gap: 0.5em;   
    }
    }
}

// ---- Pages Forum Container ---- //
.notification-modal{
    height: 0px;
    width: 20em;
    transition: height 0.5s;
    overflow: hidden;
    position: absolute;
    margin-top: 0.5em;
    right: 1em;
    z-index: 10;
    background: #FFF;
    border-radius: 0.5em;
    box-shadow: 0px 0px 0px 0px rgba(122, 122, 122, 0.10), 4px 7px 18px 0px rgba(122, 122, 122, 0.10), 15px 29px 32px 0px rgba(122, 122, 122, 0.09), 33px 64px 43px 0px rgba(122, 122, 122, 0.05), 59px 114px 51px 0px rgba(122, 122, 122, 0.01), 91px 178px 56px 0px rgba(122, 122, 122, 0.00);
    &.--forum-show{
        top: 5em;
        right: 1em;
        height: 15em;
        transition: height 0.5s;
    }
    &.--show{
        top: 8em;
        right: 1em;
        height: 15em;
        transition: height 0.5s;
    }
    .avatar-icon{
        width: 2em;
        height: 2em;
        border-radius: 50%;
    }
    ._label{
        font-family: 'Inter';
        &.--notification{
            padding: 0.5em;
            width: 100%;
            text-align: center;
            color: #282525;
            border-bottom: 2px solid rgba(0, 0, 0, 0.172)
        }
        &.--content{
            font-size: 1.2rem;
            width: auto;
            color: #3a3838;
            span{
                color: #64646490;
            }
        }
        &.--time{
            font-size: 1rem;
            color: #a8a8a8;
        }
    }
    .notification-content-row{
        overflow-y: scroll;
        overflow-x: hidden;
        height: 13em;
        
        .content-box{
            display: flex;
            align-items: center;
            .icon{
                width: auto;
            }
            padding: 0.5em;
            border-bottom: 1px solid rgba(188, 188, 188, 0.172);
        }
        .lottie-container{
            div{
               display: flex;
               justify-content: center;
               margin-top: 1em;
               margin-bottom: 1em;
               svg{
                   width: 50% !important;
               }
            }
            p{
               color: #333;
               font-size: 1.2rem;
               font-weight: 400;
               font-family: 'Inter';
               text-align: center;
               line-height: 1.3em;
            }
        }
    }
    .notification-content-row::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #c2c2c2;
    }

    .notification-content-row::-webkit-scrollbar
    {
        width: 5px;
        border-radius: 10px;
        background-color: #F5F5F5;
    }
    .notification-content-row::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #555;
    }
}

.forum-listing-card{
    font-family: 'Inter';
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    background-color: #FAFAFA;
    padding: 0.5em 0.5em 0 0.5em;
    margin: 0.5em 0;
    border-radius: 6px;
    &.--active{
        margin: 0;
        top: 0;
        left: 0;
        right: 0;
        position: fixed;
        z-index: 1031;
        overflow: hidden;
        padding: 0;
        @media screen and (min-width: 991px) {
            background: $overlay-backdrop;
            .button-back-round{
                width: 2.5em;
                height: 2.5em;
                border-radius: 2.5rem;
                background: #FFF;
                box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: -5em;
            }
        }
    }
    &.--profile-modal{
        margin: 0;
        top: 0;
        left: 0;
        right: 0;
        position: fixed;
        z-index: 9999;
        overflow: hidden;
        padding: 0;
        background: $overlay-backdrop;
        .profile-modal-container{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20em;
            max-width: 40em;
            min-height: 25em;
            background: #FFF;
            border-radius: 1.5rem;
            box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);

            .header-row{
                margin-top: 1em;
                &.--top{
                    margin-left: 0.5em;
                    margin-right: 0.5em;
                }
            }
            img{
                width: 9rem;
            }
            ._label{
                font-family: 'Inter';
                width: auto;
                &.--name{
                    font-size: 1.8rem;
                    font-weight: 500;
                    color: #FAFAFA;
                    background-color: #3DB8ED;
                    padding: 0.2em 0.8em;
                    border-radius: 2rem;
                }
                &.--info{
                    font-size: 1.6rem;
                    font-weight: 400;
                    color: #F4FBFF;
                    background-color: #222A39;
                    padding: 0.2em 0.8em;
                    border-radius: 2rem;
                }
            }
        }
        .profile-modal-container.--default::before {        
            background: unset !important;
        }
        .profile-modal-container::before{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 180px;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9));
            pointer-events: none;
            z-index: -1;
            border-radius: 1.5rem;
        }
        .button-back-round{
            width: 2em;
            height: 2em;
            border-radius: 2.5rem;
            background: #FFF;
            box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
            &.--transparent{
                background: transparent !important;
                box-shadow: none;
            }
        }
    }
    ._label{
        &.--member-detail-text{
            font-size: 1.4rem;
        }
        &.--member-detail-small-text{
            font-size: 1rem;
            color: #868686;
        }
        &.--member-type{
            font-size: 1.2rem;
            text-transform: uppercase;
            background-color: #FFD439;
            padding: 0.2em 0.5em;
            border-radius: 0.5em;
        }
        &.--post-title{
            color: #282525;
        }
        // &.--post-detail{
        //     color: #868686;
        // }
    }
    .forum-post-card-header{
        margin: 0;
        display: flex;
        align-items: center;
        .member-detail{
            width: auto;
            line-height: 0.7em;
        }
        .member-badge{
            width: auto;
            margin-left: auto;
        }
    }
    .forum-preview-content{
        padding-top: 1.8em;
        img{
            width: 100%;
            max-height: 20em;
            object-fit: cover;
            border-radius: 0.5em;
        }
        .--post-detail{
            cursor: text;
            margin: 0;
            padding: 0.5em 1.7em 0.5em 1.7em;;
            font-size: 1.4rem;
            line-height: 1.4em;
            color: #5C5C5C;
            white-space: pre-line;
            user-select: text;

            @media (max-width: 991px) {
                padding: 2em 1.7em 0.5em 1.7em;
            }

            .see-more {
                font-weight: 500;
                color: #161616;
                cursor: pointer;
            }
            .see-more:hover {
                text-decoration: underline;
            }
        }
    }
    .make-comment-container{
        background: #FFF;
        box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 1em 0.5em 2em 0.5em;
        input{
            border-radius: 1.3rem;
            background: #F6F6F6;
            border: none;
            padding: 0.5em;
            width: 100%;
            outline: none !important;
            &::placeholder{
                color: #8A8787;
            }
        }
        @media screen and (min-width: 991px) {
            position: fixed;
            right: 0;
            left: unset !important;
            width: 20.8em;
            bottom: 0;
            z-index: 1030;
            padding: 1em 0.8em 2em 0.8em !important;
            box-shadow: 6px -1px 4px 0px rgba(0, 0, 0, 0.25) !important;

        }
    }
    .create-post-overlay {
        z-index: 1;
        background: #fdfdfdab;
        width: 100%;
        height: 100%;
        position: absolute;
        height: 40em;
        width: 50em;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 2em;

        .loading-icon {
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
        }

        @media (max-width: 991px) {
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
        }
    }
}

.forum-post-info-content{
    font-family: "Inter";
    padding-top: 6em;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10em;
    overflow: scroll;
    height: 100vh;
    .post-card-listing-row{
        .title{
            margin-top: 0.5em;
            font-size: 2rem;
            text-align: center;
            font-weight: 600;
        }
        .desc{
            color: #C5C5C5;
            line-height: 1.3em;
            font-size: 1.2rem;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            max-width: 25em;
        }
    }
    @media screen and (min-width: 990px) {
        padding-top: 0em;
        background: transparent;
        height: 40em;
        overflow: unset !important;
        width: 50em !important;
        border-radius: 2em;
        position: fixed;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &.--create-post{
            padding: 3em 2em 2em 2em;
            box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
            background: #FFF;
        }
        .modal-container{
            overflow: hidden;
            padding: 0em;
            background: #FFF;
            height: 40em;
            width: 50em !important;
            border-radius: 2em;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
            .slide-pages{
                background-color: #b7b7b723;
                border-radius: 50%;
                width: 2em;
                height: 2em;
                display: block !important;
                position: absolute;
                top: 1em;
                right: 1em;
                font-size: 1.2rem;
                z-index: 2;
                p{
                    color: #868686;
                    display: flex;
                    justify-content: center;
                    line-height: 2em;
                }
            }
            .swiper{
                text-align: center;
                .swiper-wrapper{
                    align-items: center;
                }
                .swiper-button-prev, .swiper-button-next{
                    opacity: 0;
                    transition: opacity 0.3s;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    background-color: #FFF;
                    width: 2em;
                    height: 2em;
                    border-radius: 50%;
                    color: #828282 !important;
                    &::after{
                        font-size: 1.6rem !important;
                        font-weight: bold;
                        display: flex;
                    }
                }
                &:hover{
                    .swiper-button-prev, .swiper-button-next{
                        opacity: 1;
                        &::after{
                            font-size: 1.6rem !important;
                            font-weight: bold;
                            display: flex;
                        }
                        &.swiper-button-disabled{
                            display: none !important;
                        }
                        display: flex !important;
                        align-items: center;
                        justify-content: center;
                        background-color: #FFF;
                        width: 2em;
                        height: 2em;
                        border-radius: 50%;
                        color: #828282 !important;
                    }
                }
            }
        }
        .mobile-layout-container{
            padding: 0.5em 0.5em 6em 0.5em !important;
            overflow: scroll;
            height: 40em;
            .forum-post-card-header{
                position: fixed;
                background: #fff;
                top: 0;
                right: 0;
                width: 20.8em;
                z-index: 15;
                padding-left: 0.5em;
                padding-top: 1em;
                padding-bottom: 1em;
            }
        }
        .image-container-box{
            .box{
                width: 100%;
                max-height: 40em;
                img, video{
                    width: 100%;
                    height: auto;
                }
            }
        }
        .mobile-layout-container::-webkit-scrollbar{
            display: none !important;
        }
    }
    .segmented-control-container{
        border-radius: 2rem;
        background-color: #EDEDED;
        width: 17rem;
        align-items: center;
        display: flex;
        padding: 0.2em;
        .segmented-control{
            margin: 0;
            border: none !important;
            label{
                font-family: "Inter";
                font-size: 1rem;
                &::before{
                    border-radius: 1.8rem;
                    background: #FAFAFA !important;
                }
                &::after{
                    color: #333
                }
            }
        }
    }
    .forum-content{
        padding-top: 0.3em;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 0 !important;
            background-color: #FAFAFA;
        }
        .--post-detail{
            cursor: text;
            margin: 0;
            padding: 0.5em 1.7em 0.5em 1.7em;;
            font-size: 1.4rem;
            line-height: 1.4em;
            color: #5C5C5C;
            white-space: pre-line;
            user-select: text;

            @media (max-width: 991px) {
                padding: 2em 1.7em 0.5em 1.7em;
            }

            .see-more {
                font-weight: 500;
                color: #161616;
                cursor: pointer;
            }
            .see-more:hover {
                text-decoration: underline;
            }
        }
        .--post-title{
            margin: 0;
            padding: 0.5em 1em 0 1em;
            margin-top: 0.5em;
            font-size: 1.6rem;
            line-height: 1.2em;
            color: #333;
        }
        .image-container{
            .swiper{
                .swiper-wrapper{
                    height: 20em;
                }
                .swiper-pagination{
                    .swiper-pagination-bullet{
                        width: 0.3em !important;
                        height: 0.3em !important;
                        background-color: #222A39;
                    }
                }   
            }
            .image-container-box{
                .box{
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .post-location{
        display: flex;
        align-items: center;
        padding: 0 1em;
        .--member-detail-small-text{
            font-size: 1.2rem;
            &.--dot{
                font-size: 2rem;
                padding-right: 0 !important;
            }
        }
    }
    .comment-detail{
        padding: 1em;
        color: #282525;
        font-size: 1.6rem;
        // .layout-one-comment-container{ //can delete
        //     padding: 0.5em 0.8em;
        //     border-radius: 1.5rem;
        //     background: #F1F9FE;
            ._label{
                &.--comment-content{
                    color: #5C5C5C;
                    font-size: 1.4rem;
                    line-height: unset;
                }
                &.--member-detail-text{
                    font-size: 1.4rem;
                    line-height: unset;
                    font-weight: unset;
                    margin: 0;
                }
                &.--member-detail-small-text{
                    font-size: 1rem;
                    color: #868686;
                }
                &.--occupation{
                    color: #1D82AD;
                }
                &.--model{
                    color: #298D18;
                }
                &.--like{
                    margin-right: 0.5em;
                    text-align: end;
                }
                &.--comment{
                    margin-right: 0.5em;
                    text-align: end;
                }
                &.--point{
                    text-align: end;
                }
            }
        // }
        .comment-row{
            padding-left: 3em;            
            // .layout-two-comment-container{ //can delete
            //     padding: 0.5em;
            //     padding-left: 0;
            //     padding-top: 0;
            //     ._label{
            //         font-family: 'Inter';
            //         &.--comment-content{
            //             color: #5C5C5C;
            //             font-size: 1.4rem;
            //         }
            //         &.--member-detail-text{
            //             font-size: 1.4rem;
            //         }
            //         &.--member-detail-small-text{
            //             font-size: 1rem;
            //             color: #868686;
            //         }
            //         &.--occupation{
            //             color: #1D82AD;
            //         }
            //         &.--model{
            //             color: #298D18;
            //         }
            //         &.--like{
            //             margin-right: 0.5em;
            //             text-align: end;
            //         }
            //         &.--comment{
            //             margin-right: 0.5em;
            //             text-align: end;
            //         }
            //         &.--point{
            //             text-align: end;
            //         }
            //     }
            // }
        }
        .comment-action-row{
            margin: 0 0 1em 2.5em;
            .comment-action-columns{
                gap: 0.5em;
                display: flex;
            }
            p{
                color: #5C5C5C;
                width: auto;
                font-size: 1.2rem;
                font-family: "Inter";
                font-weight: 400;
                white-space: nowrap;
            }
        }
        .edit-content-comment{
            background-color: transparent;
            border-radius: 0.5em;
            border-color: #dee2e6;
            outline: none;
            padding: 0.5em;
            color: #5C5C5C;
            font-size: 1.4rem;
            min-height: 42px !important;
        }
        .--cancel-comment{
            padding: 0em 0.8em !important;
            height: 2em;
            box-shadow: none !important;
            font-size: 1.2rem;
            font-family: 'Inter';
            text-transform: capitalize;
            background-color: #4f4f4f4d;
        }
        .--save-comment{
            padding: 0em 0.8em !important;
            height: 2em;
            box-shadow: none !important;
            font-size: 1.2rem;
            font-family: 'Inter';
            text-transform: capitalize;
            background-color: #3DB8ED;
        }
    }
    .create-post-step1{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
        height: 25em;
        ._label{
            &.--choose-topic-title{
                font-size: 2.2rem;
                font-family: "Inter";
                font-weight: 700 !important;
            }
            &.--topic{
                font-size: 16px;
                color: #10141A;
                padding: 0.2em 0.8em;
                width: auto;
                border-radius: 35px;
                background: #FAFAFA;
                line-height: unset !important;
                box-shadow: 0px 0px 20px 0px rgba(175, 175, 175, 0.27) inset;
                &.--active{
                    color: #F4FBFF;
                    border-radius: 35px;
                    background: #222A39;
                    box-shadow: 0px 0px 20px 0px rgba(175, 175, 175, 0.27) inset;
                }
            }
            &.--topic-detail{
                padding: 0 2em;
            }
        }
    }
    .create-post-step3{
        .swiper-slide{
            position: relative;
            height: 144px;
        }
        ._input{
            border-radius: 0 !important;
            &.--post-title{
                width: 100%;
                resize: none;
                background-color: transparent;
                border: none;
                outline: none !important;
                caret-color: #3DB8ED;
                border-bottom: 0.1em solid #c5c5c576;
                padding: 1em 0.5em 0.5em 0.5em;
                &::placeholder{
                    padding-left: 0.5em;
                    color: #C5C5C5;
                }
                &.errors-msg{
                    &::placeholder{
                        color: #F44336;
                    }
                }
            }
            &.--post-content{
                padding: 0.5em;
                margin-top: 0.5em;
                width: 100%;
                height: 15em;
                resize: none;
                background-color: transparent;
                border: none;
                outline: none !important;
                caret-color: #3DB8ED;
                &::placeholder{
                    padding-left: 0.5em;
                    color: #C5C5C5;
                }
                &.errors-msg{
                    &::placeholder{
                        color: #F44336;
                    }
                }
            }
            &.--post-content::-webkit-scrollbar
            {
                width: 5px;
                border-radius: 10px;
                background-color: transparent;
            }
            &.--post-content::-webkit-scrollbar-thumb
            {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                background-color: #d4d4d4;
            }
        } 
        .upload-image-container{
            .p-image{
                img{
                    width: 100%;
                    height: 14.440em;
                    display: block;
                    object-fit: cover;
                    border-radius: 0.5em;
                }
            }
            video{
                position: relative;
                object-fit: contain;
                object-position: center;
                overflow: hidden;
                width: 100%;
                height: 100%;
                background-color: #EEE;
                border-radius: 0.8rem !important;
            }
            .document-upload{
                width: 100%;
                height: 144.38px;
                border-radius: 0.8rem;
                border: 0px solid #667080;
                background:  #EEE;
                position: relative;
                .container{
                    position: absolute;
                    top: 50%;
                    margin-left: auto;
                    margin-right: auto;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: grid;
                    place-items: center;
                    text-align: center;
                }
                
                p{
                    font-size: small;
                    font-family: 'Inter';
                    width: 8em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #b0b0b0;
                }
            }
            .add-post-image{
                width: 100%;
                height: 144.38px;
                border-radius: 0.8rem;
                border: 0px solid #667080;
                background:  #EEE;
                position: relative;
                svg{
                    position: absolute;
                    top: 50%;
                    margin-left: auto;
                    margin-right: auto;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .remove-icon{
                display: none;
            }
            &:hover{
                .remove-icon{
                    display: block;
                    width: 100%;
                    top: 0;
                    height: 100%;
                    background-color: #1b1b1b7a;
                    position: absolute;
                    border-radius: 0.8rem;
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    p {
                        position: absolute;
                        padding: 0.2em 0.5em;
                        bottom: 0;
                        right: 0;
                        color: #eeeeee;
                        font-size: 12px;
                        // transform: translate(-50%, -50%); 
                        // &.--error {
                        //     color: #ffb5b9;
                        // }
                    }
                }
            }
        }
        
        .post-title-row{
            position: relative;
            .suffix-input{
                color: #7b7b7b;
                width: auto;
                position: absolute;
                right: 0em;
                top: 50%;
                transform: translate(-0%, -50%);
                font-size: x-small;
            }
        }
        .post-content-row{
            position: relative;
            .suffix-input{
                color: #7b7b7b;
                width: auto;
                position: absolute;
                right: 0em;
                bottom: 1.5em;
                font-size: x-small;
            }
        }
    }
    .create-post-submit-button{
        padding: 0 3em;
        width: 25em;
        position: absolute;
        bottom: 2.5em;
        margin: 0;
        left: 50%;
        transform: translateX(-50%);
        button{
            label{
                font-size: 1.8rem !important;
            }
        }

        section {
            font-size: 1.3rem;
            padding-bottom: 0.6em;
            // text-decoration: underline;
            text-align: center;
            color: #707070;
            
            p {
                font-weight: 500;
                // border-bottom: 1px solid #868686;
                width: fit-content;
            }
        }
    }

    .preview-row {
        height: 100%;
    }
    .preview-main-tab {
        display: flex;
        padding: 0em 0.1em;
        width: 100%;
        height: 1em;

        @media screen and (max-width: 991px) {
            padding: 4.9em 0.1em 0em;
        }
    }
    .preview-content {
        margin-top: 4em;
        padding: 0em 2em;
        height: 90%;
        @media screen and (max-width: 991px) {
            padding: 0em 1em;
            margin-bottom: 4em !important;
        }

        
        .swiper{
            text-align: center;
            .swiper-wrapper{
                align-items: center;
                .image-slide {
                    width: 100% !important;
                }
            }
            .swiper-button-prev, .swiper-button-next{
                opacity: 0;
                transition: opacity 0.3s;
                display: flex !important;
                align-items: center;
                justify-content: center;
                background-color: #FFF;
                width: 2em;
                height: 2em;
                border-radius: 50%;
                color: #828282 !important;
                &::after{
                    font-size: 1.6rem !important;
                    font-weight: bold;
                    display: flex;
                }
            }
            &:hover{
                .swiper-button-prev, .swiper-button-next{
                    opacity: 1;
                    &::after{
                        font-size: 1.6rem !important;
                        font-weight: bold;
                        display: flex;
                    }
                    &.swiper-button-disabled{
                        display: none !important;
                    }
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    background-color: #FFF;
                    width: 2em;
                    height: 2em;
                    border-radius: 50%;
                    color: #828282 !important;
                }
            }
        }
    }
    .preview-tab {
        height: 4em;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14.5px;
        border-bottom: 2px solid #e2e2e2;
        z-index: 15;
        color: rgb(182, 182, 182);
        
        &.--active {
            color: #00a6eb;
            background-color: aliceblue;
            border-bottom: 2px solid #99d8f3;
        }
    }
    .preview-image-container {
        border-radius: 0.8em;
        height: 240px;
        width: 240px;
        overflow: hidden;
        border: 1px solid #dfdfdf;

        img {
            object-fit: cover;
            object-position: center;
            border-radius: 0.8em;
            height: 100%;
            width: 100%;
        }

        video {
            object-fit: cover;
            object-position: center;
            border-radius: 0.8em;
            height: 100%;
            width: 100%;
        }

        &.--mobile {
            width: 119px;
            height: 100px;
            // border-radius: 9px;
        }
    }
    .preview-text {
        line-height: unset !important;
        font-size: 12px;
        &.--bold {
            font-size: 15px;
            font-weight: 600;
        }
        &.--grey {
            font-size: 13px;
            color: #333333;
            padding-right: 2em;

            @media (max-width: 991px) {
                padding-right: 0em;
            }
        }
        &.--blue {
            font-size: 13px;
            color: #3fc5ff;
            font-weight: 600;
        }
        &.--small-blue {
            font-size: 12px;
            color: #00b2ff;
        }
        &.--note {
            margin-top: 4em;
            padding-right: 2em;

            @media (max-width: 991px) {
                padding-right: 0em;

            }

        }
    }
    .preview-slide{
        background-color: #b7b7b723;
        border-radius: 50%;
        width: 2em;
        height: 2em;
        display: block !important;
        position: absolute;
        top: 1em;
        right: 1em;
        font-size: 1.2rem;
        z-index: 2;
        p{
            color: #868686;
            display: flex;
            justify-content: center;
            line-height: 2em;
        }
    }
    .preview-image-content{
        .box{
            max-height: 40em;
            width: 100%;

            // @media (min-width: 425px) and (max-width: 770px) {
            //     max-height: 320px;
            //     width: 100%;
            // }

            @media (max-width: 991px) {
                max-height: 320px;
                width: 100%;
            }

            img {
                object-fit: cover;
                object-position: center;
                height: 100%;
                width: 100%;
            }
        }
    }
    .preview-card-container{
        @media (min-width: 991px) {
            height: 35em;
            overflow-y: scroll;
        }
    }
    .preview-card-container::-webkit-scrollbar{
        display: none;
    }
    .preview-card-header{
        // background: #fff;
        width: 100%;
        // z-index: 15;
        // padding-left: 0.5em;
        padding-top: 0em;
        padding-bottom: 1em;
        
        @media (min-width: 991px) {
            padding: 1em 0.5em;
        }

        .card-header {
            background: #fff;
            padding: 0.5em ;
            position: absolute;
            right: 0;
            top: 0;
            width: 20.8em;
            z-index: 3;
            border: 0;
        }
    }
    .preview-member-detail{
        width: auto;
        line-height: 0.7em;
    }
    ._label{
        &.--member-detail-text{
            font-size: 1.4rem;
        }
        &.--member-detail-small-text{
            font-size: 1rem;
            color: #868686;
        }
        &.--occupation{
            color: #1D82AD;
        }
        &.--model{
            color: #298D18;
        }
        &.--like{
            margin-right: 0.5em;
            text-align: end;
        }
        &.--comment{
            margin-right: 0.5em;
            text-align: end;
        }
        &.--point{
            text-align: end;
        }
    }
}

.forum-post-info-content::-webkit-scrollbar{
    display: none !important;
}

.loading-css{
    width: 100%;

    &.--like {
        width: 100%;
        height: 0; 
    }

    &.--big-like {
        background: #fafafa50;
        width: 100%;
        height: 100vh;
        z-index: 100 !important;
        position: fixed;
    }
    
    .loading-animation {
        top: 55%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -55%);

        &.--like {
            top: -1em;
            left: 50%;
            position: absolute !important;
            transform: translate(-50%, -4em);  
        }

        &.--big-like {
            top: 33%;
            left: 50%;
            position: absolute !important;
            transform: translate(-50%, -50%);
        }

        @media (min-width: 991px) {
            top: 60%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -60%);

            &.--big-like {
                top: 50%;
                left: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
            }
        }
    }

    @media (min-width: 991px) {

        &.--big-like {
            background: #fafafa50;
            width: 59%;
            height: 100%;
            z-index: 100 !important;
            position: fixed;
            border-top-left-radius: 1.8em;
            border-bottom-left-radius: 1.8em;
        }
    }
}

.filter-checkbox-modal{
    background: #FAFAFA;
    border: solid #EDEDED;
    position: absolute;
    top: 3em;
    border-radius: 0.6rem;
    width: 20em !important;
    overflow: hidden;
    transition: height 1s ease;
    z-index: 16;
    &.forum{
        top: 3.9em;
        right: 0.5em;
        width: 10em !important;
    }
    .p-multiselect{
        padding: 1em 0;
        width: 100%;
        background: transparent !important;
        border: none !important;
        .p-multiselect-item{
            background: transparent !important;
            box-shadow: none !important;
            span{
                font-size: 1.4rem !important;
            }
            .p-checkbox-box:hover{
                border-color: #3DB8ED !important;
            }
            &.p-highlight{
                .p-checkbox{
                    .p-checkbox-box{
                        border-color: #3DB8ED !important;
                        background: #3DB8ED !important;
                    }
                }
                span{
                    color: #3DB8ED !important;
                }
            }
        }
    }
}

.sharing-post-container{
    font-family: 'Inter';
    border: 1px solid #EDEDED;
    background-color: #FAFAFA;
    border-radius: 20px;
    margin-top: 2.5em;
    .post-filter-row{
        position: relative;
        margin: 1em 0em 0em 0em;
        display: flex;
        align-items: center;
        gap: 0.5em;
        .filter-tag{
            width: auto;
            margin-top: 5px;
            display: flex;
            gap: 0.5em;
            p{
                font-size: 1.35rem;
                background-color: #3DB8ED;
                color: #FAFAFA;
                padding: 0.5em 1em;
                border-radius: 0.6rem;
                display: flex;
                align-items: center;
                svg{
                    margin-left: 0.5em;
                }
            }
        }
    }
    .post-card-listing-row{
        margin-bottom: 2em;
        div{
            display: flex;
            justify-content: center;
            svg{
                width: 40% !important;
                @media screen and (min-width: 991px) {
                    width: 30% !important;
                }
            }
        }
        .title{
            margin-top: 0.5em;
            font-size: 2rem;
            text-align: center;
            font-weight: 600 !important;
            line-height: unset;
        }
        .desc{
            color: #C5C5C5;
            line-height: 1.3em;
            font-size: 1.2rem;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            max-width: 16em;
        }
    }
    @media screen and (min-width: 991px) {
        min-height: 50em;
        margin-top: 2em;
        background-color: transparent;
        border: none;
        .home-title-row{
            label{
                padding-left: 0;
            }
        }
        .search-bar-forum{
            display: flex;
            background:  #EEE;
            height: 5rem;
            min-width: 22rem;
            max-width: 35rem;
            padding: 0rem 2rem;
            align-items: center;
            border-radius: 2em;
            .icon-search{
                @media (min-width: 991px) {
                    display: contents;
                }
                //width: auto;
            }
            .search-input{
                //width: auto;
                font-size: 1.6rem;
                background-color: transparent;
                border: none !important;
                outline: none !important;
                &::placeholder{
                    color: #C5C5C5;
                }
            }
        }
    }
    .home-title-row{
        margin: 0.2em 0em 1.3em !important;

        @media (max-width: 991px) {
            margin: 0em !important;
            padding: 0.6em;
        }
    }
    ._label{
        &.--member-detail-text{
            font-size: 1.4rem;
        }
        &.--member-detail-small-text{
            font-size: 1rem;
            color: #868686;
        }
        &.--occupation{
            color: #1D82AD;
        }
        &.--model{
            color: #298D18;
        }
        &.--like{
            margin-right: 0.5em;
            text-align: end;
        }
        &.--comment{
            margin-right: 0.5em;
            text-align: end;
        }
        &.--point{
            text-align: end;
        }
    }
    .post-container{
        height: 10em;
        border-top: 1px solid #EDEDED;
        border-bottom: 1px solid #EDEDED;
    }
    .post-container-desktop{
        margin-bottom: 2.5em;
        .image-container{
            border-radius: 0.8em;
            height: 15em;
            width: 15em;
            overflow: hidden;
            .document-upload{
                height: 15em;
                width: 15em;
                border-radius: 0.8rem;
                border: 0px solid #667080;
                background:  #EEE;
                position: relative;
                .container{
                    position: absolute;
                    top: 50%;
                    margin-left: auto;
                    margin-right: auto;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: grid;
                    place-items: center;
                }
                
                label{
                    text-align: center;
                    font-size: small;
                    font-family: 'Inter';
                    width: 8em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #b0b0b0;
                }
            }
            img, video{
                object-fit: cover;
                object-position: center;
                border-radius: 0.8em;
                height: 100%;
                width: 100%;
            }
        }
        .detail{
            max-width: 15em;
            .forum-title{
                min-height: 39.97px;
                margin-top: 0.5em;
                line-height: 1.4em;
                display: -webkit-box !important;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
                overflow: hidden;
                padding-bottom: 0.1em;
            }
            .row{
                display: flex;
                p{
                    width: auto;
                    font-size: 1.2rem;
                    font-family: "Inter";
                }
            }
        }
    }
    .grid{
        margin: 1em 0 0 0;
    }
   
}

.forum-post-card-header{
    ._label{
        &.--member-detail-text{
            font-size: 1.4rem;
        }
        &.--member-detail-small-text{
            margin-top: 0.3em;
            font-size: 1rem;
            color: #868686;
        }
        &.--occupation{
            color: #1D82AD;
        }
        &.--model{
            color: #298D18;
        }
    }
    margin: 0;
    padding: 0.5em;
    align-items: center;
    .member-detail{
        width: auto;
        line-height: 0.7em;
    }

}

.forum-post-content{
    height: 6.5em;
    padding: 0;
    margin: 0;
    position: relative;
    ._label{
        font-size: 1.4rem;
        &.--like{
            margin-right: 0.5em;
            text-align: end;
        }
        &.--comment{
            margin-right: 0.5em;
            text-align: end;
        }
        &.--point{
            text-align: end;
        }
    }
    .forum-title{
        line-height: 1.4em;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;
        padding-bottom: 0em;
        font-size: 16px;
        font-weight: unset !important;
    }
    .forum-actions{
        position: absolute;
        bottom: 0.2em;
        left: 0.1em;
        justify-content: space-around;
    }
    .img-columns{
        justify-content: end;
        display: flex;
        width: 16rem;
        height: 10rem;
        overflow: hidden;
        border-radius: 9px;
        img, video{
            width: 100%;
            height: 100%;
            border-radius: 9px;
            object-fit: cover;
        }
        .react-pdf__Document{
            width: 9.6rem !important;
            height: 9.6rem !important;
            border-radius: 9px !important;
            overflow: hidden;
            .react-pdf__Page__canvas{
                object-fit: cover !important;
            }
        }
    }
    .document-upload{
        width: 96px;
        height: 96px;
        border-radius: 0.8rem;
        border: 0px solid #667080;
        background:  #EEE;
        position: relative;
        .container{
            position: absolute;
            top: 50%;
            margin-left: auto;
            margin-right: auto;
            left: 50%;
            transform: translate(-50%, -50%);
            display: grid;
            place-items: center;
        }
        svg{
            width: 2.5em;
        }
        label{
            font-size: small;
            font-family: 'Inter';
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #b0b0b0;
        }
    }
}

// ---- Pages Profile Container ---- //
.profile-container{
    padding: 0em 1.2em 5em 1.2em !important;
    &.desktop-container{
        padding: 32px 7.5px 100px !important;
    }
    .user-div{
        &.--mobile{
            padding-top: 4.5em;
            padding-bottom: 0.8em;
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            margin: 0;
        }
        &.--desktop{
            margin: 0;
            border-radius: 1.5em;
            padding: 1.2em 1.2em 2.2em;
            ._label{
                &.--baner-title{
                    font-size: 2.3rem;
                    color: #F4FBFF;
                    line-height: unset !important;
                }
                &.--name{
                    font-size: 2rem;
                }
                &.--point{
                    font-size: 1.6rem;
                    line-height: 1em;
                }
                &.--blue{
                    font-size: 1.6rem;
                    line-height: 1em;
                    text-align: center;
                }
                &.--info{
                    font-size: 1.6rem;
                    line-height: 1em;
                    text-align: center;
                }
            }
            .user-data-row{
                display: flex;
                margin-top: 2em;
                align-items: center;
                .avatar-col{
                    display: flex;
                    justify-content: end;
                    .avatar-row{
                        height: 7.5em;
                        width: 7.5em;
                    }
                }
                .data-col{
                  padding-right: 8em;
                }
            }
        }
        .user-data-row{
            max-width: 100% !important;
            margin: 0;
        }
        .avatar-col{
            display: flex;
            justify-content: center;
            .avatar-row{
                height: 5.625em;
                width:  5.625em;
                align-items:center;
                justify-content: center;
                .user-avatar{
                    border-radius: 50%;
                }
                .change-icon{
                    z-index: 22;
                    margin-top: -2em;
                    margin-left: 3em;
                }
            }
        }
        .name-row{
            justify-content: center;
            max-width: 100% !important;
            text-align: center;
            margin: 0;
            .col{
                padding: 0;
            }
        }
       ._label{
        white-space: nowrap;
        &.--name{
            font-size: 2rem;
            border-radius: 2em;
            background: #00000087;
            width: auto;

            color: #FAFAFA;
            font-size: 1.8rem;
            margin: 0.2em !important;
            padding: 0.1em 1em;
            input{
                border: none !important;
                outline: none !important;
                box-shadow: none !important;
            }

            h3 {
                font-size: 20px !important;
                line-height: unset !important;
            }
        }
        &.--blue{
            color: #222A39;
            font-size: 1.2rem;
            border-radius: 1.3em;
            background: #3DB8ED;
            line-height: 1em;
            margin: 0.2em;
            padding: 0.3em 0.8em;
            text-align: center;
            min-width: 6em;
            max-width: 9em;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            @media (min-width: 991px) and (max-width: 1170px) {
                max-width: 5em;
                min-width: 0 !important;
            }
        }
        &.--point{
            color: #222A39;
            font-size: 1.2rem;
            border-radius: 1.3em;
            background: #3DB8ED;
            padding: 0.125em 0.625em;
            line-height: 1em;
            margin: 0.2em;
            padding: 0.3em 0.8em;
            text-align: center;
            min-width: 6em;
        }
        &.--info{
            color: #222A39;
            font-size: 1.2rem;
            border-radius: 1.3em;
            background: #F6CA44;
            line-height: 1em;
            margin: 0.2em;
            padding: 0.3em 0.8em;
            text-align: center;
            min-width: 6em;
            max-width: 9em;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            @media (min-width: 991px) and (max-width: 1170px) {
                max-width: 5em;
                min-width: 0 !important;
            }
        }
        &.--login{
            color: #FAFAFA;
            font-size: 1.7rem;
            cursor: pointer; 
        }
       } 
       .login-row{
            margin-top: 1.7em;
            text-align: center;
            padding-bottom: 1.7em;
       }
    }
    ._label{
        &.--mission{
            display: flex;
            color: #222A39;
            font-size: 2.2rem;
            align-items: center;
            line-height: unset;
        }
        &.--logout{
            color: #333333;
            font-size: 1.6rem;
            text-decoration: underline;
        }
        &.--version{
            color: #5C5C5C;
            font-size: 1.2rem;
        }
    }
    .mission-container{
        .mission-div{
            margin-top: 1em;
            padding-bottom: 1em;
            border-bottom: 1px solid rgba(83, 173, 240, 0.20);
            &:last-child{
                border-bottom: none;
            }
            &.--locked{
                opacity: 0.5;
            }

            .items-row{
                margin: 0;
                height: 3.5em;
                justify-content: center;
                position: relative;
                .target-label{
                    position: absolute;
                    bottom: 0em;
                    left: 50%;
                    color: #C5C5C5;
                    font-size: 1.4rem;
                    z-index: 21;
                    font-weight: 700;
                    width: auto;
                    transform: translate(-50%, 0);
                }
               ._label{
                &.--name{
                    color: #5C5C5C;
                    font-size: 1.6rem;
                }
               } 
               .image-col{
                   display: flex;
                   align-items: center;
                   justify-content: center;
                   .image-div{
                         width: 3.5em;
                         height: 3em;
                       .mission-image{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 0.5em;
                       }  
                   }
               } // can delete
                //    .task-col{
                //     border-radius: 1em;
                //     text-align: center;
                //     background: #EEEEEE;
                //     overflow: hidden;
                //    }
                //    .task-col.active {
                //         color: #222A39;
                //         background: linear-gradient(to right, #EEEEEE 0%, #222A39 100%);
                //         background-size: 200% auto;
                //         animation: slideBackground 1s linear forwards;
                //     }
                // .bonus-image{
                //     padding: 0;
                //     position: absolute;
                //     bottom: -0.28em;
                //     right: 1em;
                //     z-index: 20;
                //     width: 2.2em;
                //     height: 2.2em;
                //     border-radius: 50%;
                //     overflow: hidden;
                //     border: 1px solid black;
                //     // box-shadow: 0 0 10px #6bbcdf;
                //     object-fit: cover !important;
                //     // animation: shine_green 2s infinite; 
                // }
                
                // @keyframes shine_green {
                // 0% {
                //     box-shadow: 0 0 10px #13485f;
                // }
                // 50% {
                //     box-shadow: 0 0 20px rgba(164, 228, 255, 0.603);
                // }
                // 100% {
                //     box-shadow: 0 0 10px #13485f;
                // }
                // }

                .absolute-container {
                    position: absolute;
                    bottom: -0.28em;
                    right: 1em;
                    z-index: 20;
                    width: 2.2em;
                    height: 2.2em;
                    border-radius: 50%;
                    overflow: hidden;
                    border: 1px solid black;
                    padding: 0;
                    // object-fit: cover;
                }

                .shine {
                    position: absolute;
                    overflow: hidden;
                    width: 50px;
                    height: 50px;
                    display: inline-block;
                    border-radius: 5px;
                    color: #fff;
                    text-decoration: none;
                    text-align: center;
                    line-height: 50px;
                    font-size: 12px;
                    font-family: sans-serif;
                    // background: #9c8d9c;
                    z-index: 99;
                
                    &::after {
                        content: "";
                        position: absolute;
                        top: -110%;
                        left: -210%;
                        width: 200%;
                        height: 200%;
                        opacity: 0;
                        transform: rotate(30deg);
                        
                        // background: rgba(255, 255, 255, 0.13);
                        background: linear-gradient(
                            to right, 
                            rgba(255, 255, 255, 0.13) 0%,
                            rgba(255, 255, 255, 0.13) 77%,
                            rgba(255, 255, 255, 0.5) 92%,
                            rgba(255, 255, 255, 0.0) 100%
                        );
                        animation: shineEffect 1.5s ease infinite;
                    }
                }
                
                @keyframes shineEffect {
                    0% {
                        opacity: 0;
                        top: -110%;
                        left: -210%;
                    }
                    50% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                        top: -50;
                        left: 0;
                    }
                }

                .bonus-image {
                    // position: absolute;
                    // bottom: -0.28em;
                    // right: 1em;
                    // z-index: 20;
                    width: 100%;
                    height: 100%;
                    // border-radius: 50%;
                    // overflow: hidden;
                    // border: 1px solid black;
                    object-fit: cover;
                    
                    // Your existing styles...
                } 
            }
        }
    }
    .view-all-mission-button{
        width: 15em;
        margin-left: auto;
        margin-right: auto;
        height: 30px;
        margin-bottom: 2em;
        button{
            color: #333 !important;
            box-shadow: none !important;
            text-transform: capitalize;
            font-family: 'Inter';
            font-size: 1.6rem;
            border-radius: 1.2rem;
            background: #ffffff !important;
        }
    }
    .get-mission-row{
        align-items: center;
        justify-content: center;
        background: #212a3a26;
        padding: 1em;
        border-radius: 1em;
        margin: 0.5em 0em 0em 0em;
        ._label{
            font-family: 'Inter';
            text-align: center;
            font-size: 1.5rem;
        }
        .logo-div{
            height: 6em;
            width: 6em;
            .logo-image{
                height: 100%;
                width: 100%;
            }
        }
    }
    
    .profile-setting-div{
        margin-bottom: 1.5em;
        ._label{
            font-family: 'Inter';
            &.--title{
                color: #222A39;
                font-size: 2.2rem;
                line-height: unset !important;
            }
            &.--name{
                color: #333333;
                font-size: 1.6rem;
            }
            &.--desc{
                color: #888888;
                font-size: 1.2rem;
                line-height: 10px;
            }
        }
        .items-row{
            padding-top: 1em;
            padding-bottom: 0.6em;
            border-bottom: 0.1rem solid #DDDDDD;
            
            h4 {
                line-height: unset;
                font-weight: unset;
                margin-bottom: 0;
            }
        }
    }
    .desktop{
        padding: 0em 12em;
        margin-top:1.5em;
    }
    .desktop-user{
        padding: 0em 2em;

    }
    .mobile{
        margin-top:15em;
    }
   
}

.empty-div{
    text-align: center;
    .image-row{
        height: 11em;
        width: 13em;
        margin: auto;
        margin-top: 3em;
        margin-bottom: 3em;
    }
    h3{
        font-size: 1.8rem;
        font-weight: 500 !important;
        line-height: 1.4em;
    }
    p{
        font-size: 1.33rem;
    }
}

.forum-post-container{
    .sticky{
        padding-top: 2em;
        background-color: #FFF;
        position: fixed;
        top: 0em;
        left: 0;
        z-index: 5;
        width: 100%;
    }
    .header-forum{
        color:  #333;
        margin: 0;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0 7.5px;

        h1 {
            font-size: 2.2rem;
            font-weight: 500 !important;
        }

        .action-button{
            justify-content: end;
            display: flex;
            gap: 1em;
        }
    }
    .search-bar-forum{
        margin-top: 1em;
        display: flex;
        background:  #EEE;
        height: 5rem;
        padding: 0rem 2rem;
        align-items: center;
        .icon-search{
            width: auto;
        }
        .search-input{
            width: auto;
            font-size: 1.6rem;
            background-color: transparent;
            border: none !important;
            outline: none !important;
            &::placeholder{
                color: #C5C5C5;
            }
        }
    }
    .post-card-listing-row{
        margin-top: 12em;
        div{
            display: flex;
            justify-content: center;
            svg{
                width: 50% !important;
            }
        }
        .title{
            margin-top: 1em;
            font-size: 2rem;
            text-align: center;
            font-weight: 600 !important;
            line-height: unset !important;
        }
        .desc{
            color: #C5C5C5;
            line-height: 1.3em;
            font-size: 1.2rem;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            max-width: 16em;
        }
    }
    .listing-container{
        margin-top: 12em;
        padding-bottom: 10em;
    }
}

.success-modal{
    .round-bg {
        margin: 3em 0em 2em 0em;
        width: 27em;
        border-radius: 100%;
        background: aliceblue;
    }
    .success-title {
        color: #58aacc;
        text-align: center;
        font-size: 30px;
        line-height: unset;
        margin: 0;
    }
    .success-desc {
        padding: 1em 1em;
        text-align: center;
        font-size: 15px;
        color: #666666;  
    }
    .modal-content {
        border-radius: 20px;
    }
    .view-btn{
        text-transform: capitalize;
        border-radius: 5px;
        padding: 0.8em 2.2em;
        width: auto;
        font-size: 15px;
        font-family: 'inter-regular';
        background: #3db8ed !important;
        box-shadow: none !important;
    }
    .view-btn:hover, .view-btn:active{
        background: #3db8ed;
        box-shadow: none !important;
    }
}

// ---- Modalbox Container ---- //

.login-modal-container{
    .modal-dialog{
        display: flex;
        align-items: center;

        @media (min-width: 991px) {
            margin-top: auto;
            margin-right: auto;
            margin-left: auto;
            max-width: fit-content;
        }

        @media (min-width: 767px) {
            margin-top: 3em;
            margin-right: auto;
            margin-left: auto;
            max-width: fit-content;
        }
    }
    .modal-content{
        background: transparent;
    }
    .modal-body{
        height: 90em !important;
        width: 50em;
        background: rgba(34, 42, 57, 1);
        border-radius: 14px;
        padding: 2em !important;
        @media (max-width: 767px) {
            min-height: 100vh;
            // height: auto !important;
            width: auto;
            border-radius: 0px;
        }
        svg{
            cursor: pointer;
        }
    }
    .login-row-header{
        text-align: center;
        justify-content: center;
        margin-top: 1em;
    }
    // .sign-in-div{ // can delete
    //     margin: 1em 0em 2em 0.5em;
    //     border-bottom: 0.3em solid #7d86a4;
    //     width: 3em;

    //     &.--long{
    //         width: 5em;
    //     }
    // }
    .login-row-button{
        justify-content: center;
        padding: 0em;
        &.--space{
            margin-bottom: 4em;
        }

        @media (min-width: 500px) {
            padding: 0em 2em 0em 2em;
        }
    }
    .tc-row{
        input[type=checkbox] {
            position: absolute;
            width: 0px;
            height: 0px;
          }
          
          input[type=checkbox]:checked:before {
                content: "";
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            border: 2px solid rgba(214, 214, 214, 1);
            border-radius: 20px;
            background-color: #445768;  
            transition: all 0.2s linear;
            top: 6px;
            left: 13px;
          }
          
          
          input[type=checkbox]:before {
            content: "";
            display: block;
            position: absolute;
            width: 15px;
            height: 15px;
            border: 2px solid rgba(214, 214, 214, 1);
            border-radius: 3px;
            background-color: #445768;
            top: 6px;
            left: 13px;
          }
          
          
          input[type=checkbox]:after {
            content: "";
            display: block;
            width: 0px;
            height: 0px;
            border: solid rgba(214, 214, 214, 1);
            border-width: 0 0px 0px 0;
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            position: absolute;
            top: 0px;
            left: 50px;
            transition: all 0.2s linear;
          }
          
          input[type=checkbox]:checked:after {
            content: "";
            display: block;
            width: 6px;
            height: 13px;
            border: 2px solid #d6d6d6;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            position: absolute;
            top: 4px;
            left: 20px;
          }
    }
    .form-group{
        padding: 0.5em;
        &._text{
            .phone-input{
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 0.1em solid #ededed;
                border-radius: 0 !important;
                font-size: 16px;
                color: #eeeeee;
                background: unset;
                width: 100%;
                outline: none !important;

                @media (min-width: 770px) {
                    width: 100% !important;
                }
            }
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                caret-color: white;
                -webkit-text-fill-color: #eeeeee;
                -webkit-background-clip: text;
                transition: background-color 5000s ease-in-out 0s;
            }
        }
        &._errors{
            position: unset !important;
            .phone-input{
                border-radius: 0 !important;
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 0.1em solid #dc4c64 !important;
                color: #dc4c64;
                background: unset;
                width: 100%;
                outline: none !important; 
        
                @media (min-width: 770px) {
                    width: 100% !important;
                }
            }
        }
    }
    ._label{
        &.--title{
            margin: 0;
            font-size: 1.8rem;
            color: #bbcad1;
            text-align: center;
            letter-spacing: 0.1em;
        }
        &.--title-span{
            margin: 0;
            font-size: 2.5rem;
            background: linear-gradient(90deg, #9ecde1 25%, #26b5f3 86%);
            -webkit-text-fill-color: transparent;
            -webkit-background-clip: text;
            text-align: center;
            font-weight: 600;
            text-transform: uppercase;
        }
        &.--reunion-title{
            margin: 0;
            font-size: 20px;
            font-family: "Inter";
            text-transform: uppercase;
            color: #fafafa;
            letter-spacing: 0em;
        }

        &.--welcome-subtitle{
            margin: 0;
            font-size: 20px;
            font-family: 'Inter';
            color: #fafafa;
            letter-spacing: 0.2em;
            line-height: unset !important;
        }
        &.--tc{
            color: #c5c5c5;           
            font-size: 12px;
            margin-left: 0em;

            @media (max-width: 500px) {
                margin-left: 1em;
            }
        }
        &.--tc-span{
            cursor: pointer;
            color: #3DB8ED;
            font-size: 12px;
        }
    } 
    .button-group{
        font-family: "Inter";
        width: 80%;
        border-radius: 0.8em;
        height: 4.6rem;
        font-size: 14px;
        box-shadow: none !important;

        @media (min-width: 991px) {
            font-size: 16px;
        }

        &.--login{
          font-family: "Inter" !important;
          white-space: nowrap;
          padding: 0.8em;
          margin-bottom: 1.5em;
          letter-spacing: 0.1em;
        }
        &.--sign-in{
            white-space: nowrap;
            margin: 5em 0em 1.5em 0em;
            letter-spacing: 0.1em;
            background-color: #3db8ed;
            box-shadow: 0px 4px 0px 0px #1279ac !important;
            font-weight: 400;
          }
        &.--sign-up{
            white-space: nowrap;
            letter-spacing: 0.1em;
            color: #eeeeee;
            border: 0.15em solid #eeeeee;
            background-color: transparent;
            font-weight: 400;
        }
        &.--send-code{
            white-space: nowrap;
            padding: 1em 0.8em !important;
            margin: 2em 0em 1em 0em;
            letter-spacing: 0em;
            text-decoration: underline;
            background-color: transparent;
        }
        &.--sign-in-no-space{
            color: rgba(34, 42, 57, 1);
            white-space: nowrap;
            letter-spacing: 0em;
            background: rgba(61, 184, 237, 1);
            border-radius: 8px;
            box-shadow: 0px 0px 20px 0px rgba(175, 175, 175, 0.27) inset;
        }
        &.--sign-in-no-space:disabled{
            color: #fafafa !important;
            white-space: nowrap;
            letter-spacing: 0em;
            background-color: transparent;
            border-radius: 8px;
            box-shadow: 0px 0px 20px 0px rgba(175, 175, 175, 0.27) inset !important;
        }
    }
}
.login-modal-container::-webkit-scrollbar {
    display: none !important;
}

//store
.store-container{
    padding: 32px 7.5px 100px !important;
    .store-search{
        width: 70%;
        height: 43px;
        margin: 1em;
        display: flex;
        align-items: center;
        border-radius: 12px;
        justify-content: center;
        border: 1px solid #3db8ed;
        box-shadow: 0px 4px 0px 0px #4797d1;

        @media (min-width: 991px) {
            width: 50%;
        }
        @media (max-width: 500px ) {
            width: 353px;
        }

        .search-col{
            display: flex;
            outline: none;
            padding: 0em 1em;
            font-size: 18px;
            border: none !important;
            color: #5c5c5c;
        }
        .icon-col{
            flex: auto;
            height: 43px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
            background: #3db8ed;
        }
    }
    .line-container{
        display: flex;
        position: relative;
        justify-content: center;
        margin: 1em 0 0 0;
        .store-line{
            height: 0.01em;
            margin: 0.3em 0;
            background-color:rgba(175, 172, 172, 1);
            position: absolute;
            top: 0.5em;
            width: 100%;
        }
    }
    .product-card{
        color: #4f4f4f;

        h3 {
            color: #4f4f4f;
            padding: 0.3em 0.5em;
            display: -webkit-inline-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: normal;
            width: 10em;
            height: 5em;
            text-align: center;
            word-break: break-all;
            text-overflow: ellipsis;
            font-weight: unset;
            font-size: 16px;

            @media (max-width: 991px) {
                font-size: 1.2rem;
            }
        }

        @media (min-width: 991px) {
            height: 24rem;
            font-size: 1.6rem;
        }

        line-height: 1.3em;
        font-size: 1.2rem;
        font-family: 'Inter';
        white-space: nowrap;
        gap: 4px;
        width: 100%;
        height: 13em;
        padding: 0.5em;
        border-radius: 1em;
        box-shadow: 2px 4px 9px 0px rgba(163, 163, 163, 0.2);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .text-detail{
            display: grid;
            text-align: center;
            label{
                width: 100%;
            }
        }
        .product-img{
            padding: 0.3em;
            @media (min-width: 991px) {
                padding: 0.8em 0.5em;
                width: 160px;
                height: 154px;
                flex-shrink: 0;
            }
            overflow: hidden;
            border-radius: 0.8em;
            width: 100px;
            height: 100px;
            img {
                border-radius: 0.3em;
                object-fit: cover;
                width: 100%;
                height: 100% !important;
            }
        }
    }
    ._label{
        &.--welcome-title{
            font-size: 22px;
            text-align: center;
            justify-content: center;
            font-family: "Inter";
            color: #333333;
            line-height: 1.6em;
        }
        &.--line-title{
            z-index: 2;
            width: auto;
            font-size: 22px;
            color: #333333;
            text-align: center;
            justify-content: center;
            font-family: "Inter";
            background-color: #ffffff;
        }
    }
    .skeleton-row{
        margin-top: 1.5em;
        align-items: center;
        .skeleton-col{
            .p-skeleton{
                margin: auto;
            }
        }
    }
}
.store-container::-webkit-scrollbar {
    display: none !important;
}

//product
.product-overlay {
    width: 100vw;
    height: 100vh;
    background: #fafafad9;
    z-index: 1050;
    position: absolute;
    overflow-y: hidden;
}
.product-header{
    z-index: 2;
    height:80px;
    width: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    background: #ffffff;
    padding: 2em 3em 1.25em 3em;
    justify-content: space-between;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);

    .header-title{
        color: #333333;
        font-size: 16px;
        font-family: "Inter";
    }
    @media (min-width: 500px) {
        height: 70px;
    }
}
.product-container{
    top: 4em;
    left: 50%;
    transform: translate(-50%);
    padding: 0em !important;
    position: absolute;
    min-width: 100%;
    .image-row{
        min-height: 16em;
        margin: 0;
        padding: 0.6em 0em 0.2em 0em;
        position: relative;
        box-shadow: inset 0 -12px 5px -10px rgba(0, 0, 0, 0.25);
        .image-div{
            width: 90%;
            img{
                object-fit: cover;
                width: 60%;
                height: 25em;
                outline: 0 !important;
                @media (max-width: 500px) {
                    width: auto;
                    height: 14.6em;
                }
            }
            @media(max-width: 500px) {
                padding-top: 1.2em;
            }
        }
        .image-count{
            width: 52px;
            height: 21px;
            display: flex;
            color: #fff;
            border-radius: 11px;
            align-items: center;
            justify-content: center;
            font-family: "Inter";
            background:rgba(156, 156, 156, 1);

            position: absolute;
            top: 90%;
            left: 50%;
            transform: translate(-50%, -90%);
            z-index: 10;

            &.--loading{
                background: #dfe5ee
            }
        }
        @media (max-width: 500px) {
            padding: 0;
        }
    }
    .loading-overlay {
        position: fixed;
        top: -5em;
        width: 100%;
        height: 20em;
        background: #26b5f305;
    }
    .product-info{
        width: 100%;
        padding: 1.5em;
        margin-bottom: 7em;
        .product-basic{
            .product-tag{
                text-align: center;
                display: flex;
                width: auto;
                font-size: 12px;
                border-radius: 6px;
                margin-right: 0.5em;
                align-items: center;
                justify-content: center;
                padding: 5px 10px 5px 10px;
                font-family: "Inter";
                background: rgba(244, 251, 255, 1);
            }
        }
        .product-more{
            .short-line{
                width: 5px;
                height: 23px;
                border-radius: 2px;
                background: rgba(61, 184, 237, 1);
                margin-right: 0.5em;
            }
            .function-div{
                background: rgba(244, 251, 255, 1);
                border-radius: 8px;
                padding: 1em;
            }
            .product-function {
                margin: 0;
                color: #4f4f4f;
                transition: height 1s;

                &.--hide {
                    color: #4f4f4f;
                    overflow: hidden;
                    transition: height 1s;
                    z-index: 1;
                }
            }

            .product-function::-webkit-scrollbar {
                display: none !important;
            }
            
            .guide-div{
                height: 277px;
                font-size: 12px;
                color: rgba(92, 92, 92, 1);
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
                @media (max-width: 991px) {
                    width: 345px;
                    img {
                        object-fit: cover;
                    }
                }
            }
        }

        @media (min-width: 991px) {
            min-width: 700px;
            max-width: 700px;
            width: min-content;
        }
    }
    ._label{
        &.--product-name{
            color: #333333;
            font-size: 20px;
            font-family: "Inter";
        }
        &.--brand-name{
            color: #5c5c5c;
            font-size: 14px;
            font-family: "Inter";
        }
        &.--short-title{
            color: #333333;
            font-size: 18px;
            font-family: "Inter";
        }
        &.--see-more{
            color: rgba(34, 42, 57, 1);
            font-size: 12px;
            font-weight: 700;
            font-family: "Inter";
            text-decoration: underline;
            margin-bottom: 0 !important;
        }
        &.--guide-desc{
            color: #5c5c5c;
            font-size: 12px;
            font-weight: 400;
            font-family: "Inter";
        }
        &.--price-unit{
            font-size: 14px;
            color: #000000;
            text-transform: uppercase;
        }
    }
}
.product-container::-webkit-scrollbar {
    display: none !important;
}
.product-footer{
    left: 50%;
    z-index: 2;
    height: 114px;
    width: 100%;
    position: fixed;
    align-items: center;
    background: transparent;
    padding: 5em 3em 3em 3em;
    transform: translate(-50%);
    .purchase-btn, .purchase-btn:active{
        border-radius: 12px;
        font-family: "Inter";
        font-size: 18px;
        background: rgba(61, 184, 237, 1) !important;
        box-shadow: 0px 4px 0px 0px rgba(71, 151, 209, 1) !important;
        text-transform: capitalize;
        padding: 0.47em 2.5em;
    }
    .purchase-btn:disabled{
        background: #c3c3c3  !important;
        box-shadow: 0px 4px 0px 0px #777777 !important;
        opacity: 1;

        &.--loading {
            background: #7ecff0 !important;
            box-shadow: none !important;
            height: 46px;
        }
    }
}

//cart
.cart-btn {
    border-radius: 100% !important;
    width: 8em;
    height: 8em;
    padding: 2em 2em 2em 1.7em !important;
    background: #3db8eb !important;
    box-shadow: 0px 3px 4px 0px #c9c9c9;
    position: fixed !important;
    right: 30px;
    bottom: 30px;
    z-index: 5;

    svg{
        color: #fafafad7;
    }
    
    @media (max-width: 991px) {
        bottom: 88px;   
    }
}
.cart-btn:hover {
    background: #d2f0ff !important;
    transition: background 0.8s ease;

    svg{
        color: #3db8ed;
        transition: color 0.8s ease;
    }
}

.cart-container{
    padding: 32px 7.5px 0px 0px;

    @media (max-width: 991px) {
        padding: 0 !important;
    }

    .payment-type-tab{
        .tab {
            font-size: 16px;
            width: 8em;
            text-align: center;
            padding: 0.75em 1em !important;
            &.--active{
                background: aliceblue;
                border-bottom: 4px solid #3db8eb;
            }
        }
    }

    .image-div{
        width: 10em;
        height: 8em;
        margin-right: 1.5em;
        min-width: 10em;

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }

        @media (max-width: 991px) {
            width: 8em;
            height: 8em;
            margin-right: 0em;
            padding: 0.8em;
        }
    }

    .merchant-row {
        width: 100%;
        td{
            text-align: left;
            font-weight: 500;
            padding: 1em;
            background: #ebfaff;
        }

        @media (max-width: 991px) {
            background-color: #f0f8ff;
            padding: 1em;
        }
    }

    .long-line {
        border-bottom: 0.1em solid #dfdfdf;
    }

    table {
        width: 100%;
        thead {
            background-color:#f7f8fa;
            tr{
                th{
                    color: #727272;
                    text-align: center;
                    font-family: "Inter";
                    padding: 1em 0em 1em;
                    font-weight: 400;
                }
                ._th{
                    text-align: left;
                    padding: 1em;
                    &.--product {
                        max-width: 300px;

                        @media (max-width: 991px) {
                            max-width: none;
                        }
                    }
                }
            }
        }
        tbody {
            background-color: #ffffff;
            margin-top: 3em;
            border: 1px solid #f0efef;
            tr{
                td{
                    text-align: center;
                }
                ._td{
                    padding: 1em;
                    &.--product {
                        text-align: left;
                        max-width: 300px;
                        label {
                            display: block;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .MuiNumberInput-root {
        border: 1px solid #cbeefd;
        border-radius: 8px;
        width: 7em;
        height: 2.5em;

        @media (max-width: 991px) {
            width: 6em;
            height: 2em;
        }

        .MuiNumberInput-decrementButton {
            background: #ebfaff;
            width: 44px;
            height: 38px;
            border-radius: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            color: unset !important;

            @media (max-width: 991px) {
                height: 30px;
            }
        }
        .MuiNumberInput-decrementButton:hover{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background: #a2e3ff;
            color: unset !important;
        }
        .MuiNumberInput-input {
            border: 0;
            padding: 0px;
            height: 2em;
            z-index: 0;
            margin: 0;
            font-size: 16px;
            @media (max-width: 991px) {
                font-size: 14px;
            }
        }
        .MuiNumberInput-incrementButton {
            background: #ebfaff;
            width: 44px;
            height: 38px;
            border-radius: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            color: unset !important;

            @media (max-width: 991px) {
                height: 30px;
            }
        }
        .MuiNumberInput-incrementButton:hover{
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background: #a2e3ff;
            color: unset !important;
        }
    }

    .grey-color {
        color: #7a7a7a;
    }

    .grey-space{
        background: #f1f1f1;
        padding: 1em;

        @media (max-width: 991px) {
            padding: 0.5em;
        }
    }

    .cart-footer{
        margin-top: 2em;
        width: 100%;
        height: 5em;
        background: #f7f8fa;
        position: sticky;
        bottom: 0;
        padding: 0em 2em;
        display: flex;
        align-items: center;
        justify-content: end;
        box-shadow: 0px -3px 10px -5px #8f9599;
        button {
            font-family: "Inter";
            font-size: 16px;
            background-color: #3db8eb;
            text-transform: capitalize;
            font-weight: unset !important;
            border-radius: 6px;
            padding: 0.5em 2em;
            @media (max-width: 991px) {
                padding: 0.5em 1em;
            }
        }
        &.--no-shadow {
            box-shadow: none !important;
        }

        @media (max-width: 991px) {
            bottom: 7%;
            margin: 0;
        }
    }

    ._label{
        font-family: "Inter";
        &.--cart-title{
            color: #333333;
            font-size: 25px;
            margin: 1em 0em !important;
        }
        &.--brand-name{
            color: #8a8a8a;
            font-size: 14px;
        }
        &.--empty-cart{
            font-size: 18px;
            font-style: italic;
            font-weight: 400;
            color: lightgrey;

            @media (max-width: 991px) {
                font-size: 16px;
            }
        }
        &.--total-span{
            font-size: 1.4em;
            color: #333333;

            @media (max-width: 991px) {
                font-size: 1.3em;
            }
        }
    }

    ._mobile-label{
        font-size: 14px;
        font-family: "Inter";
        &.--product-name{
            color: #000000;
        }
        &.--brand-name{
            color: #8a8a8a;
        }
        &.--price{
            color: #00a6eb;
            font-weight: 500;
        }
    }
}
.cart-container::-webkit-scrollbar {
    display: none !important;
}

//checkout 
.checkout-container{
    padding: 32px 7.5px 0px 0px;

    .mobile {
        &.--merchant-row{
            border-bottom: 1px solid #eeeeee;
            text-align: left;
            font-weight: 500;
            padding: 0.8em 1em;

            h4 {
                font-size: 16px;
            }
        }
        &.--product {
            label {
                font-size: 14px;
                white-space: wrap;
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
            }

            .grid-div {
                display: grid;
                width: 100%;
                grid-template-rows: 1fr 1fr;

                p {
                    &.--name {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    table {
        width: 100%;
        thead {
            background-color:#f7f8fa;
            tr{
                th{
                    color: #727272;
                    text-align: center;
                    font-family: "Inter";
                    font-size: 14px;
                    padding: 1em 0em 1em;
                    font-weight: 400;
                }
                ._th{
                    text-align: left;
                    padding: 1.4em 1em;
                    &.--product {
                        max-width: 300px;

                        @media (max-width: 991px) {
                            max-width: none;
                        }
                    }
                }
            }
        }
        tbody {
            background-color: #ffffff;
            margin-top: 3em;
            .merchant-row {
                border-bottom: 1px solid #eeeeee;
                width: 100%;
                td{
                    text-align: left;
                    font-weight: 500;
                    padding: 1em;

                    h4 {
                        font-size: 14px !important;
                    }
                }
            }
            tr{
                td{
                    vertical-align: top;
                    font-size: 14px;
                    text-align: center;
                    padding: 1em;

                    div {
                        display: flex;
                    }
                }
                ._td{
                    padding: 1em;
                    &.--product {
                        text-align: left;
                        max-width: 300px;
                        .image-div{
                            width: 8em;
                            min-width: 6em;
                            margin-right: 1.5em;
                        }
                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                        label {
                            white-space: nowrap;
                            display: block;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            width: 100%;
                        }
                    }
                    &.--quantity {
                        .quantity-picker {
                            border: 1px solid #cbeefd;
                            border-radius: 8px;
                            .quantity-modifier{
                                height: 2.3em;
                                background: #ebfaff;
                                font-size: 1.6rem;
                                color: #474747;
                                font-weight: 500;
                                font-family: 'inter-regular';
                            }
                            .modifier-left:hover{
                                border-top-left-radius: 5px;
                                border-bottom-left-radius: 5px;
                                background: #a2e3ff;
                            }
                            .modifier-right:hover{
                                border-top-right-radius: 5px;
                                border-bottom-right-radius: 5px;
                                background: #a2e3ff;
                            }
                        }
                    }
                }
            }
        }
    }

    .bottom-row {
        display: flex;
        justify-content: end;
        align-items: center;
        font-weight: 500;

        &.--shipping {
            padding: 1.1em 1em;

            @media (max-width: 991px) {
                background-color:#f7f8fa;
            }
        }

        &.--order-total {
            padding: 0.7em 1em;
            background: #f0fafd;
        }
    }

    .place-order-btn {
        background-color: #3db8eb !important;
        box-shadow: none !important;
        width: auto;
        font-size: 1em;
        padding: 0.6em 2em;
        border-radius: 10px;
        text-transform: capitalize;

        @media (max-width: 1200px) {
            padding: 0.6em 1em !important;
        }
    }

    .grey-color {
        color: #7a7a7a;
    }

    .short-line{
        width: 5px;
        height: 25px;
        border-radius: 2px;
        background: rgba(61, 184, 237, 1);
        margin-right: 0.5em;
    }

    .card {
        background-color: #fafafa;
        height: auto;

        @media (max-width: 991px) {
            background-color: #ffffff;
        }
    }

    .summary-card {
        background: #fafafa;
        box-shadow: none !important;
        border-radius: 10px;
        padding: 1em;
        border: 1px solid #ededed;
    }

    .info-div{
        border-radius: 10px;
        padding: 10px 15px 10px 15px;
        border: 1px solid rgba(237, 237, 237, 1);
        &.--blue{
            border: 1px solid rgba(61, 184, 237, 1);
            box-shadow: 0px 0px 8px 0px rgba(65, 177, 205, 1) inset;
        }
        .add-div{
            width: fit-content;
            height: 31px;
            align-items: center;
            border-radius: 6px;
            padding: 8px 10px 8px 10px;
            background:rgba(245, 245, 245, 1);
        }
        .username-div{
            width: fit-content;
            height: 31px;
            align-items: center;
            border-radius: 6px;
            padding: 8px 10px 8px 10px;
            background: #fafafa;
            border: 2px solid rgba(61, 184, 237, 1);
        }
    }

    ._label{
        font-family: "Inter";
        &.--checkout-title{
            color: #333333;
            font-size: 25px;
            margin: 1em 0em !important;
        }
        &.--time-left{
            font-size: 14px;
            padding: 0.3em 1.1em;
            color: #3db8eb;
            border-radius: 20px;
            background-color: #cff2fd96;
        }
        &.--short-title{
            font-size: 20px;
            color: #333333;
        }
        &.--short-subtitle{
            font-size: 12px;
            color: rgba(92, 92, 92, 1);
        }
        &.--brand-name{
            color: #979797;
            font-size: 12px;
        }
        &.--empty-cart{
            font-size: 18px;
            font-style: italic;
            font-weight: 400;
            color: lightgrey;
        }
        &.--order-total{
            font-size: 14px;
            color: #727272;
            font-weight: 400;
        }
        &.--total-span{
            font-size: 1.2em;
            color: #333333;
        }
        &.--shipping-option{
            font-size: 14px;
            color: #3db8eb;
        }
        &.--shipping-type{
            font-size: 14px;
            color: #727272;
            font-weight: 400;
        }
        &.--change-shipping{
            border-radius: 8px;
            padding: 0.4em 1em;
            font-size: 14px;
            color: #fafafa;
            background-color: #3db8eb;
        }
        &.--change-shipping:hover{
            color: #3db8eb;
            background-color: #cff2fd96;
            transition: all 1s ease-out;
        }
        &.--add{
            font-size: 12px;
            color: rgba(112, 112, 112, 1);
        }
        &.--form-data{
            font-size: 14px;
            text-transform: capitalize;
            color: rgba(92, 92, 92, 1);
        }
        &.--username{
            font-size: 12px;
            color: rgba(34, 42, 57, 1);
        }
        &.--total-payment{
            font-size: 1.2em;
            color: #3db8eb;;
        }
        &.--error-msg{
            font-size: 12px;
            color: #dc4c64;

            p {
                svg {
                    margin-top: 0.08em;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
}
.checkout-container::-webkit-scrollbar {
    display: none !important;
}

//shipping 
.shipping-info {
    padding: 10px 10px 20px;
    margin-bottom: 6em;

    @media (max-width: 991px) {
        padding: 0px 20px;
    }

    .save-btn{
        background-color: initial;
        box-shadow: none!important;
        color: #5c5c5c;
        font-family: Inter;
        font-size: 18px;
        height: 44px;
        padding: 16px 116px;
        text-decoration: underline;
        text-transform: capitalize;
        width: auto;
    }    
    .save-btn:hover{
        background-color: transparent;
        color: #5c5c5c;
    }
    .save-btn:disabled{
        background-color: transparent;
        color: #979797;
    }
    .form-group{
        padding: 0.5em;
        &._text{
            .contact-input{
                height: 60px;
                width: 100%;
                border-radius: 8px;
                padding: 20px 16px 20px 16px;
                border: 1px solid rgba(237, 237, 237, 1);
                background: linear-gradient(0deg, #EDEDED, #EDEDED), linear-gradient(0deg, #FAFAFA, #FAFAFA);
                outline: none !important;

                font-size: 14px;
                font-family: "Inter";
                color: rgba(92, 92, 92, 1);
            }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            caret-color: #333333;
            -webkit-text-fill-color: #333333;
            -webkit-background-clip: text;
            background-color: #ededed !important;
        }
        }
        &._errors{
            position: unset !important;
            .contact-input{
                border: 0.1em solid #dc4c64 !important;;
                color: #dc4c64;
                background: unset;
                width: 100%;
                outline: none !important; 
            }
        }
    }
}

//purchase
.purchase-modal-container{
    [data-rsbs-content] {
        display: flex;
        //min-height: 75vh;
        align-items: flex-start;
    }
    [data-rsbs-scroll]::-webkit-scrollbar {
        display: none !important;
    }
    // can delete
    // .purchase-modal-header{
    //     display: flex;
    //     margin-top: 1em;
    //     padding: 0em 1em;
    //     justify-content: space-between;
    // }
    // .header-div{
    //     border-bottom: 1px solid rgba(221, 221, 221, 1)
    // }
    .purchase-modal-body{
        padding-bottom: 2em;
        .short-line{
            width: 5px;
            height: 23px;
            border-radius: 2px;
            background: rgba(61, 184, 237, 1);
            margin-right: 0.5em;
        }
        .info-div{
            border-radius: 10px;
            padding: 10px 15px 10px 15px;
            border: 1px solid rgba(237, 237, 237, 1);
            &.--blue{
                border: 1px solid rgba(61, 184, 237, 1);
                box-shadow: 0px 0px 8px 0px rgba(65, 177, 205, 1) inset;
            }
            .add-div{
                width: fit-content;
                height: 31px;
                align-items: center;
                border-radius: 6px;
                padding: 8px 10px 8px 10px;
                background:rgba(245, 245, 245, 1);
            }
            .username-div{
                width: fit-content;
                height: 31px;
                align-items: center;
                border-radius: 6px;
                padding: 8px 10px 8px 10px;
                background: #fafafa;
                border: 2px solid rgba(61, 184, 237, 1);
            }
        }
        .method-div{
            width: 3.5em;

            &.--choose{
                width: 3.5em;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media (min-width: 991px) {
                width: 4em;

                &.--choose{
                    width: 3em;
                }
            }
        }
        input[type=radio] {
            position: relative;
            cursor: pointer;
        }
        input[type=radio]:before {
            content: "";
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            top: -12%;
            left: -8.5%;
            border: 1px solid rgba(61, 184, 237, 1);
            border-radius: 100%;
            background-color: white;
        }
        input[type=radio]:checked:after {
            content: "";
            display: block;
            width: 18px;
            height: 18px;
            border: 5px solid rgba(61, 184, 237, 1);
            border-radius: 100%;
            position: absolute;
            top: -12%;
            left: -16%;
        }
        .payment-logo{
            width: 3.5em;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        @media(max-width: 600px) {
            padding: 0em 1em;
        }
    }
    .form-group{
        padding: 0.5em;
        &._text{
            .contact-input{
                height: 64px;
                width: 100%;
                border-radius: 8px;
                padding: 20px 16px 20px 16px;
                border: 1px solid rgba(237, 237, 237, 1);
                background: linear-gradient(0deg, #EDEDED, #EDEDED), linear-gradient(0deg, #FAFAFA, #FAFAFA);
                outline: none !important;

                font-size: 14px;
                font-family: "Inter";
                color: rgba(92, 92, 92, 1);
            }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            caret-color: #333333;
            -webkit-text-fill-color: #333333;
            -webkit-background-clip: text;
            background-color: #ededed !important;
            // transition: background-color 5000s ease-in-out 0s;
        }
        }
        &._errors{
            position: unset !important;
            .contact-input{
                border: 0.1em solid #dc4c64 !important;;
                color: #dc4c64;
                background: unset;
                width: 100%;
                outline: none !important; 
            }
        }
    }
    ._label{
        font-family: "Inter";
        &.--header-title{
            font-size: 18px;
            color: #333333;
        }
        &.--item-name{
            font-size: 22px;
            color: #333333;
        }
        &.--item-price{
            font-size: 24px;
            color: #333333;
        }
        &.--price-unit{
            font-size: 14px;
            color: #000000;
            text-transform: uppercase;
        }
        &.--each-price{
            font-size: 14px;
            color: rgba(131, 130, 154, 1);
        }
        &.--short-title{
            font-size: 20px;
            color: #333333;
            line-height: unset;
            margin: 0;
        }
        &.--short-subtitle{
            font-size: 12px;
            color: rgba(92, 92, 92, 1);
        }
        &.--short-span{
            font-size: 12px;
            color: #26b5f3;
        }
        &.--cost{
            font-size: 13px;
            color: #333333;
        }
        &.--method_name{
            font-size: 16px;
            color: rgba(34, 42, 57, 1);
        }
        &.--add{
            font-size: 12px;
            color: rgba(112, 112, 112, 1);
        }
        &.--form-data{
            font-size: 14px;
            text-transform: capitalize;
            color: rgba(92, 92, 92, 1);
        }
        &.--username{
            font-size: 12px;
            color: rgba(34, 42, 57, 1);
        }
        &.--error-msg{
            font-size: 12px;
            color: #dc4c64;

            p {
                svg {
                    margin-top: 0.08em;
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    .purchase-modal-footer{
        //min-height: 28em;
        ._button-group{
            &.--pay-btn{
                width: auto;
                height: 44px;
                font-size: 18px;
                color: #fafafa;
                border-radius: 12px;
                font-family: "Inter";
                padding: 11px 80px 11px 80px;
                background-color: rgba(61, 184, 237, 1);
                box-shadow: 0px 4px 0px 0px rgba(71, 151, 209, 1);
                margin-bottom: 2em;
                @media (max-width: 991px) {
                    font-size: 16px;
                    padding: 12px 60px 12px 60px;
                }
            }
            &.--save-btn{
                width: auto;
                height: 44px;
                font-size: 18px;
                background-color: unset;
                color: rgba(92, 92, 92, 1);
                font-family: "Inter";
                padding: 11px 116px 11px 116px;
                box-shadow: none !important;
                text-decoration: underline;
                text-transform: capitalize;
            }
        }
    }
    .modal-footer{
        display: unset;
    }
}

// ---- Footer ---- //
.footer-container {
    z-index: 1;
    position: relative;

    &.--stick {
        position: fixed;
        bottom: 0;
        left: auto;
        right: auto;
        width: 100%;
        transform: translateY(0%);
        transition: transform 0.5s ease;
    }

    &.--hidden {
        position: fixed;
        bottom: 0;
        left: auto;
        right: auto;
        width: 100%;
        transform: translateY(100%);
        // transition: transform 0.5s ease;
        // transform: translateY(100%);
    }

    ._label {
      font-size: 14px;
      font-family: "inter-regular";
      color: #C5C5C5;
  
      &.--desc-text {
        line-height: unset;
        margin: 0;

        &.--special {
          padding-top: 0.5em;
          font-size: 1.2rem;
        }
  
        @media (min-width: 991px) {
          width: auto;
        }
      }
  
      &.--item-text {
        &.active {
          color: #3db8ed;
        }
      }
  
      &.--item-text:hover {
        color: #3db8ed;
      }
  
      &.--highlight-text {
        color: #648dff //#577DE5;
      }
    }
  
    &.footer-one {
        padding: 2em 1em 10em 1em;
        background: #222A39;
        .listing-item-container {
            transition: height 0.5s;
            overflow: hidden;
    
            &.active {
            transition: height 0.5s;
            }
        }
        .line-container {
            margin: 1.2em 0 0.4em;

            &.--grey-thin{
                height: 0.5px;
                width: 100%;
                background-color: #C5C5C5;
            }
        }
        .image-frame {
            width: 100px;
            height: 30px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        @media (min-width : 991px) {
            padding: 2em 1em 3em 1em;
        }
    }
  
    @media (min-width : 991px) {
      &.footer-two {
        //display: block;
        bottom: 0;
        position: absolute;
      }
    }
}

// ---- segmented-control ---- //
.sharing-post-tab-container{
    font-family: 'Inter';
    margin: 0;
    background-color: transparent !important;

    @media screen and (min-width: 991px) {
        background-color: #EDEDED;
        display: flex;
        border-radius: 2em;
        padding: 0.3em;
        height: 5rem;
        align-items: center;
        margin-left: auto;
        justify-content: end;
    }

    .ant-segmented {
        transition: none !important;

        border: 0;
        padding: 0;
        background-color: #ffffff;

        @media screen and (min-width: 991px) {
            width: 55%;
        }

        @media (min-width: 991px) and (max-width: 1200px) {
            width: 80%;
        }

        .ant-segmented-group {
            transition: none !important;

            .ant-segmented-thumb {
                border-radius: 0;
                color: #ffffff;        
                background-color: #3DB8ED;
                
                @media (min-width: 991px) {
                    border-radius: 50px;
                    width: 80%;
                }

            }
            .ant-segmented-item {
                margin: 0 !important;
                border: none !important;
                border-radius: 0;
                height: 4.5rem;

                line-height: 2.8em;
                
                display: flex;
                align-items: center;
                justify-content: center;
                transition: none !important;

                @media screen and (min-width: 991px) {
                    border-radius: 50px;
                }
                
                &.ant-segmented-item-selected {
                    background-color: #3DB8ED !important;
                    color: #ffffff;
                    transition: none !important;

                    .ant-segmented-item-label {
                        font-size: 16px;
                        font-family: 'Inter';
                        color: #ffffff;
    
                        transition: none !important;
                    }
                }
                
                .ant-segmented-item-label {
                    font-size: 16px;
                    font-family: 'Inter';
                    color: #000000;

                    transition: none !important;
                }
            }
        }
    }

    .segmented-control{
        margin: 0 !important;
        color: #3DB8ED !important;
        border: none !important;
        label{
            color: black !important;
            height: 4.3rem;
            line-height: 2.8em;
        }    
        label::after{
        }    
        label::before{
            color: #3DB8ED !important;
            @media screen and (min-width: 991px) {
                color: #3DB8ED !important;
                border-radius: 2em !important;
            }
        }
    }
}

// ---- Pages Event purchase ---- //
.purchase-modal{
    ._label{
        &.--title{
            font-size: 1.8rem;
            color: $text-color--primary;
        }
        &.--name{
            font-size: 2.2rem;
            color: $text-color--primary;
        }
        &.--price{
            font-size: 2.4rem;
            color: $text-color--primary;
        }
        &.--text{
            font-size: 1.4rem;
            color: #5C5C5C
        }
    }
    .modal-header{
        align-items: center;
    }

    .event-item{
        align-items: center;
    }
}

// Common Short Div
.short-div{
    width: 5px;
    height: 23px;
    border-radius: 2px;
    background: rgba(61, 184, 237, 1);
    margin-right: 0.5em;
}

// ---- Calendar Car Inspection ---- //
.workshop-container{
    border-radius: 0.8rem;
    height: 20rem;

    @media (min-width: 991px) {
        height: 22rem;
    }
}
.swiper{
    margin-top: 1em;
    .swiper-wrapper{
        .swiper-slide:first-child{
        }
    }
}

.booking-car-container{
    padding: 32px 7.5px 100px;
}

.calendar-card-container{
    padding: 0 1.3em !important;
    margin-top: 1.5em !important;
    .--calander-detail{
        margin-top: 1.5em;
        margin-bottom: 1.5em !important;
        color: #828892;
        font-size: 1.2rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
    .banner-title{
        font-family: 'Inter';
        font-weight: 400;
        font-size: 2rem;
        color: #333;
        @media screen and (min-width: 991px) {
            //margin-top: 1.5em !important;
            font-size: 2.8rem;
        }
    }
    .notice-booking{
        height: 3em;
        max-width: 32em;
        margin-left: auto;
        margin-right: auto;
        p{
            @media screen and (min-width: 991px) {
                font-size: 1.2rem;
            }
            font-size: 1rem;
            display: flex;
            align-items: center;
            svg{
                margin-right: 0.5em;
            }
            width: 100%;
            padding: 0.5em;
            border-radius: 0.5em;
            background: #F4FBFF;
            box-shadow: 0px 0px 0px 0px rgba(163, 163, 163, 0.10), 5px 4px 14px 0px rgba(163, 163, 163, 0.10), 18px 18px 25px 0px rgba(163, 163, 163, 0.09), 41px 39px 34px 0px rgba(163, 163, 163, 0.05), 72px 70px 40px 0px rgba(163, 163, 163, 0.01), 113px 110px 44px 0px rgba(163, 163, 163, 0.00);
            &.--expired{
                background: #e3e3e3;
                color: #a1a1a1;
            }
        }
    }
    .booking-history-row{
        max-width: 52em;
        margin: 3.5em 0 3em 0;
        justify-content: space-between;
        align-items: center;
        h5{
            text-align: center;
            width: 2em;
            font-weight: 400;
            font-family: 'Inter';
            font-size: 1.4rem;
            position: relative;
            &.--expired{
                color: #e2dddd;
            }
            
            span{
                display: block;
                position: absolute;
                bottom: -12px;
                left: 50%;
                transform: translateX(-50%);
                color: #e3e3e3;
            }
        }
        @media screen and (min-width: 991px) {
            h5:nth-child(5){
                width: auto;
                color: #EEEEEE;
                background-color: #222A39;
                border-radius: 2em;
                padding: 0.5em 1.2em;
                &.--expired{
                    // color: #4F4F4F;
                    // background-color: #e3e3e3;
                }
            }
        }
        @media screen and (max-width: 991px) {
            h5:nth-child(4){
                width: auto;
                color: #EEEEEE;
                background-color: #222A39;
                border-radius: 2em;
                padding: 0.5em 1.2em;
                &.--expired{
                    // color: #4F4F4F;
                    // background-color: #e3e3e3;
                }
            }
        }
    }
    .calendar-box-container{
        margin-left: auto!important;
        margin-right: auto!important;
        .picker-time{
            margin-top: 0.3em;
            width: 100%;
            // &.car_plate{
            //     width: 15em;
            // }
            @media screen and (max-width: 991px) {
                margin-top: 0.3em;
                max-width: 30em;
                margin-right: auto;
                margin-left: auto;
            }
            .css-1jqq78o-placeholder,
            .css-1dimb5e-singleValue,  
            .css-qbdosj-Input{
                font-size: 1.6rem !important;
                @media screen and (max-width: 991px) {
                    font-size: 1.2rem !important;
                }
                font-weight: 400;
            }
            // .css-1xc3v61-indicatorContainer{
            //     padding: 0 1px 0 1px !important;
            // }
            .css-5736gi-menu{
                font-size: 1.2rem !important;
                font-weight: 600;
            }
        }
        @media screen and (min-width: 991px) {
            .react-calendar{
                width: 100% !important;
            }
            .--calander-detail{
                display: flex;
                justify-content: end;
            }
        }
        margin: 0;
        max-width: 50em;
        padding: 1.5em;
        background-color: #FAFAFA;
        border-radius: 0.8rem;
        border: 1px solid  #EDEDED;
        box-shadow: 0px 0px 0px 0px rgba(113, 153, 190, 0.08), -5px 10px 25px 0px rgba(113, 153, 190, 0.08), -19px 41px 45px 0px rgba(113, 153, 190, 0.07), -43px 92px 61px 0px rgba(113, 153, 190, 0.04), -76px 163px 72px 0px rgba(113, 153, 190, 0.01), -118px 254px 79px 0px rgba(113, 153, 190, 0.00);
        .p-float-label{
            margin-top: 2em;
            @media screen and (min-width: 991px) {
                margin-top: 0.5em;
            }
            .p-calendar{
                width: 100%;
                height: 5em;
                border-radius: 1.2rem;
                // border: 1px solid #C5C5C5;
                @media screen and (min-width: 991px) {
                    height: 4.2em;
                }
            }
            input{
                padding-left: 1em;
                padding-top: 1.5em;
                font-size: 1.4rem;
                box-shadow: none !important;
                border-color: #ced4da !important;
            }
            label{
                padding-left: 0.8em;
                color: #C5C5C5;
                font-size: 1.6rem;
                line-height: 0.6em;
            }
        }
        .p-float-label input:focus ~ label, .p-float-label input:-webkit-autofill ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label, .p-float-label .p-tooltip-target-wrapper ~ label{
            top: 1.5em;
            font-size: 1rem;
        }
        .css-t3ipsp-control{
            z-index: 2 !important;
        }
    }
    .booking-input-row {
        margin-top: 1em !important;

        .booking-input {
            width: 100%;
            padding: 2px 8px;
            transition: all 100ms;
            background-color: hsl(0, 0%, 100%);
            border-color: hsl(0, 0%, 80%);
            border-radius: 4px;
            border-style: solid;
            border-width: 1px;
            box-sizing: border-box;
            min-height: 38px;
            outline: 0!important;
            position: relative;
            align-items: center;
            display: flex;
            flex-wrap: wrap;

            @media (max-width: 991px) {
                font-size: 1.2rem !important;
                color: hsl(0, 0%, 20%);
            }
        }
    }
}

.our-workshop-partner{
    padding: 0 !important;
    .swiper{
        padding-left: 1.8em;
    }
    .workshop-container{
        .workshop-button{
            text-transform: capitalize;
            color: #EEEEEE;
            position: absolute;
            bottom: 1.5em;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 2rem;
            background: rgba(0, 0, 0, 0.30) !important;
            backdrop-filter: blur(4px);
            box-shadow: none !important;
            
            h4 {
                font-size: 1.2rem;
            }
        }
        .workshop-box{
            width: 100%;
            overflow: hidden;
            &.--hide{
                height: 0rem;
                padding: 0;
                // transition: height 2s;
            }
            &.--active{
                border-radius: 0.8rem;
                background: rgba(0, 0, 0, 0.50);
                backdrop-filter: blur(10px);
                padding: 0.5em;
                height: 13.76em;

                @media (max-width: 991px) {
                    height: 12.6em;
                }
                // transition: height 2s;
            }
            label{
                width: 100%;
                font-family: 'Inter';
            }
            .title{
                text-align: center;
                color: #EEE;
                font-size: 1.2rem;
                font-weight: 500;
            }
            .label{
                font-size: 1.4rem;
                color: #C5C5C5;
                line-height: 1em;
            }
            .value{
                font-size: 1.2rem;
                color: #EEEEEE;
                font-weight: 400;
            }
            .row{
                justify-content: space-evenly;
                img{
                    padding: 0;
                    width: 2em;
                    height: 2em;
                }
            }
        }
    }
}

.car-report-container{
    padding: 0 !important;
    .report-car-row-mobile{
        margin: 0;
        padding: 0 2em;
        .card{
            margin: 1.5em 0em 3em 0em;
            img{
                display: flex;
                margin-left: auto;
                margin-right: auto;
                height: 100%;
                width: 100%;
            }
        }
    }
    .image-div{
        img{
            // height: 25em !important;
            object-fit: cover;
            display: flex;
            margin-left: auto;
            margin-right: auto;
            height: 100%;
            width: 100%;
        }
    }
    .swiper{
        padding-left: 1.8em;
    }
    .card{
        margin-bottom: 0em;
        overflow: hidden;
        border-radius: 1.4rem;
        background: #FAFAFA;
        .card-body{
            padding: 0;
            .detail{
                padding: 0.1em 1em;
            }
            .report-label{
                padding: 0.3em 0.2em;
                color: #333;
                font-size: 1.2rem;
                font-weight: 500;
            }
            .value-label{
                padding: 0.3em 0.2em;
                color: #333;
                font-size: 1.2rem;
                font-weight: 500;
            }
            .model-label{
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                color: #333;
                font-size: 1.2rem;
                font-weight: 500;
            }
            .title-box{
                color: #F4FBFF;
                background-color: #222A39;
                padding: 1em;
                .title{
                    font-family: 'Inter';
                    text-align: center;
                    font-size: 1.8rem;
                    font-weight: 400;
                    width: 100%;
                }
                .detail{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: #C5C5C5;
                    font-size: 12px;
                    width: 100%;

                    svg {
                        width: 19px;
                        height: 19px;
                    }
                }
            }
        }
    }
}

.get-car-report-row{
    align-items: center;
    justify-content: center;
    background: #212a3a26;
    padding: 1em;
    border-radius: 1em;
    margin: 0.5em 0em 0em 0em;
    ._label{
        font-family: 'Inter';
        text-align: center;
        font-size: 1.5rem;
    }
    .logo-div{
        height: 6em;
        width: 6em;
        .logo-image{
            height: 100%;
            width: 100%;
        }
    }
}

.react-calendar{
    margin-left: auto;
    margin-right: auto;
    width: 30.8rem !important;
    background-color: transparent !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    padding-bottom: 1em !important;
    border-bottom: 1px solid #EDEDED !important;
    .react-calendar__navigation{
        margin: 0;
        height: 30px;
        font-size: 1.4rem;

        button {
            min-width: 40px;
        }
        .react-calendar__navigation__label {
            min-width: 100px !important;

            .react-calendar__navigation__label__labelText{
                white-space: nowrap;
                color: #222a39;
            }
        }
    }
    .react-calendar__navigation__arrow {
        color: #222a39;
    }
    abbr{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2em;
        height: 2em;
        font-size: 1.2rem !important;
        margin-left: auto;
        margin-right: auto;
    }
    .react-calendar__tile{
        padding: 0.2em;
    }
    // .react-calendar__tile--now{
    //     background-color: transparent !important;
    //     &.react-calendar__year-view__months__month{
    //         padding: 0 !important;
    //         abbr{
    //             width: auto;
    //             height: 100%;
    //             border-radius: 0.5em;
    //             color: #3DB8ED;
    //             border: 1px solid #3DB8ED;
    //             background-color: transparent !important;
    //             font-size: 1.2rem !important;
    //         }
    //     }
    //     abbr{
    //         border-radius: 0.5em;
    //         color: #3DB8ED;
    //         border: 1px solid #3DB8ED;
    //         background-color: transparent !important;
    //         font-size: 1.2rem !important;
    //     }
    // }
    .react-calendar__tile--active, .react-calendar__tile--hasActive{
        color: #FAFAFA !important;
        background-color: transparent !important;
        &.react-calendar__tile--now{
            
            abbr{
                color: #FAFAFA !important;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0.5em;
                width: 2em !important;
                height: 2em !important;
                // padding: 0.5em 0.8em;
                background-color: #3DB8ED !important;
                font-size: 1.2rem !important;
            }
        }
        &.react-calendar__year-view__months__month{
            padding: 0 !important;
            abbr{
                color: #FAFAFA !important;
                width: auto !important;
                height: 100% !important;
                border-radius: 0.5em;
                color: #3DB8ED;
                background-color: #3DB8ED  !important;
                font-size: 1.2rem !important;
            }
        }
        abbr{
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.5em;
            width: 2em !important;
            height: 2em !important;
            // padding: 0.5em 0.8em;
            background-color: #3DB8ED !important;
            font-size: 1.2rem !important;
        }
    }
    .react-calendar__navigation button:disabled{
       color: transparent;
       background-color: transparent;
    }

    .react-calendar__month-view__days__day--weekend,
    .react-calendar__month-view__days__day {
        color: #5C5C5C;
    }
    .react-calendar__month-view__days__day--neighboringMonth{
        color: #75757547;
    }
}

.purchase-page{
  [data-rsbs-overlay]{
      z-index: 9999;
  }
  [data-rsbs-backdrop]{
    z-index:9997 !important;
  }
  [data-rsbs-scroll]::-webkit-scrollbar{
    display: none;
  }
  .purchase-modal{
    padding: 0px 0.75px;
    @media (min-width: 991px) {
        padding: 1em 2em;
    }
  }
  .short-line{
    width: 5px;
    height: 23px;
    border-radius: 2px;
    background: rgba(61, 184, 237, 1);
    margin-right: 0.5em;
  }
  ._label{
      &.--title{
          font-size: 1.68rem;
          color: $text-color--primary;
      }
      &.--name{
          font-size: 2.2rem;
          line-height: 1.2em;
          color: $text-color--primary;
      }
      &.--price{
          font-size: 2.4rem;
          color: $text-color--primary;
      }
      &.--price-unit{
          font-size: 14px;
          color: #000000;
          text-transform: uppercase;
      }
      &.--text{
          font-size: 1.4rem;
          color: #5C5C5C
      }
      &.--form-title{
          line-height: unset;
          margin: 0;
          font-size: 2rem;
          color: $text-color--primary;
      }
      &.--detail{
          font-size: 1.2rem;
          color: #5C5C5C
      }
      &.--add_btn{
          font-size: 1.2rem;
          color: #707070
      }
      ._span{
        color: #26b5f3;
      }
  }
  .modal-header{
      padding: 1em 0em;
      align-items: center;
  }
  .event-container{
    margin-top: 5em;
    &.desktop{
    margin-top: 1em;
    }
}
  .event-item{
      margin: 1em 0em;
      align-items: center;
  }
  .tag{
      border-left: 0.3em solid #3DB8ED;
      border-radius: 0.3em;
  }
.info-div{
    padding: 0.5em 1em;
    margin-top: 1em;
    border-radius: 0.5em;
    border: 1px solid var(--outline-gray, #EDEDED);
    .add_btn{
        height: 31px;
        width: auto;
        display: flex;
        align-items: center;
        padding: 0.5em 0.625em;
        border-radius: 6px;
        background: #F5F5F5;
    }
}
  .attendeen-div::-webkit-scrollbar{
    display: none;
}
  .info-div::-webkit-scrollbar{
      display: none;
  }
  .error-message{
      margin-top: 0.1em;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      color: #EA3943 !important;
  }
  .payment-row{
      margin: 1.5em 0.5em 0.5em 0.5em;
      align-items: center;
      .image-col{
          width: 2.875em ;
          height: 1.9em;
          margin: auto;
          .payment-image{
              height: 100%;
              // width: 100%;
              object-fit: cover;

          }
      }
      ._label{
          &.--name{
           font-size: 1.4rem;
           color :#222A39
          }
      }
  }
  .payment-quantity-div{
    margin-top: 1.5em;
      .quantity-row{
        background: #3db8ed15;
        border-radius: 10px;
        height: 4rem;
        display: flex;
        align-content: center;
          label{
            color: #626668 !important;
            font-size: 1.7rem;
          }
      }
  }
}

.comfirmation-modal{
    .modal-content{
        padding: 1em;
        .modal-header{
            border: none !important;
            padding-bottom: 0;
            .modal-title{
                font-size: 1.6rem;
                font-family: 'Inter';
                font-weight: 600;
            }
        }
        .modal-body{
            padding-top: 0;
            p{
                width: 100%;
                color: #838383b7;
                font-size: 1.2rem;
            }
        }
        .modal-footer{
            padding-top: 2em;
            border: none !important;
            .close-button{
                text-transform: capitalize;
                font-size: 1.2rem;
                color: #7a7a7a;
                box-shadow: none !important;
                background-color: #a0a0a05f !important;
            }
            .comfirm-button{
                text-transform: capitalize;
                font-size: 1.2rem;
                color: #FAFAFA;
                box-shadow: none !important;
            }
        }
    }
}

.full-modal-box-container{
    z-index: 99;
    .modal-body::-webkit-scrollbar{
        display: none !important;
    }
    .modal-content{
        min-height: 100vh;
    }
    @media (min-width: 991px) {
        .modal-dialog{
            display: flex;
            justify-content: center;
        }
        .modal-content{
            border-radius: 2em;
            width: 50em;
            min-height: 0;
        }
        .modal-body{
            height: 80em;
            overflow-y: unset;
        }
    }
}

.full-modal-box-container::-webkit-scrollbar {
    display: none !important;
}    

.react-pdf__Document{
    width: 100%;
    height: 100%;
    .react-pdf__Page{
        width: 100%;
        height: 100%;
        .react-pdf__Page__canvas{
            object-fit: contain !important;
            width: 100% !important;
        }
        .react-pdf__Page__textContent{
            display: none;
        }
        .react-pdf__Page__annotations{
            display: none;
        }
    }
    .react-pdf__message{
        font-size: 1rem;
        width: 100%;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}

.full-modal-box-container{
    &.--full-modal{
        z-index: 99999;
        .modal-dialog{
            top: 50%;
            transform: translate(0, -50%) !important;
            .modal-content{
                border-radius: 1em;
                overflow: hidden;
                .forum-post-container{
                    border-radius: 0.5em;
                }
            }
        }
    }
}

.forum-post-container{
    padding: 0.5em 1em !important;
    @media screen and (min-width: 991px) {
        padding: 1em !important;
    }
    .setting-box{ 
        background-color: #e3e3e3;
        border-radius: 0.8em;
        padding: 0.5em;
        a:focus-visible {
            outline: none !important;
        }
        .setting-tab:first-child{
            padding-top: 0.5em;
        }
        .setting-tab{
            margin: 0;
            display: flex;
            padding-top: 1em;
            .icon-section,
            .detail-section {
                font-size: 16px;
                font-family: 'Inter';
                font-weight: 600;
                line-height: 1em;
                color: #494949;

                h4 {
                    font-size: 16px;
                    font-weight: 600 !important;
                    margin: 0;
                    line-height: 1em !important;
                }

                .text-small{
                    width: 100%;
                    font-size: 1.2rem;
                    font-weight: 500;
                    color: #8e8e8e;
                }
            }
            &:hover{
                .icon-section,
                .detail-section {
                    font-family: 'Inter';
                    font-weight: 600;
                    line-height: 1em;
                    color: #757575b8;
                    .text-small{
                        width: 100%;
                        font-size: 1.2rem;
                        font-weight: 500;
                        color: #757575b8;
                    }
                }
            }
        }
        
    }
}

.progressBarFancyContainer{
    &.disable{
        .progressFill, .false{
            display: none !important;
        }
    }
    .labelScoreContainer{
        font-family: 'Inter';
        color: #5C5C5C !important;
        font-size: 1.6rem;
        font-weight: 500;
        .label{
            margin: 0;
        }
        .number{
            display: none;
        }
    }
    .progressTrack{
        margin: 0 !important;
        height: 25px !important;
        border-radius: 2em !important;
    }
    .progressFill{
        filter: none !important;
        height: 25px !important;
        border-radius: 2em !important;
    }
} 

.share-modal-container{
    .modal-content{
        min-height: 100vh;
    }
    @media (min-width: 991px) {
        .modal-dialog{
            display: flex;
            justify-content: center;
        }
        .modal-content{
            border-radius: 2em;
            width: 50em;
            min-height: 0;
        }
        .modal-body{
            height: 90em;
            overflow-y: unset;
        }
    }
  .body-container{
    margin: 1em 0em;
    padding: 1em 2em;
    @media (max-width: 991px) {
        max-width: none !important;
        display: flex;
        justify-content: center;
        margin: 5em 0em 0em 0em;
        padding: 1em 2em;
    }
    ._action-card{
        display: flex;
        align-items: center;
        justify-content: center;
        &.--social{
            margin-top: 0.5em;
            padding: 0.5em 1em;
            border: 0.1em solid #AFACAC;
            border-radius: 0.5em;
            font-family: 'inter-regular';
            &:hover{
                background-color: #33333320;
                color: #4f4f4f;
            }
        }
        &.--save{
            font-size: 14px;
            margin-top: 0.5em;
            padding: 0.5em 1em;
            font-family: 'inter-regular';
        }
    }
  }
}

.react-share__ShareButton:focus-visible{
    outline: none !important;
}

.report-container-modal{
    .modal-body{
        overflow: auto !important;
    }
    .blue-tag{
        border-radius: 2px;
        padding: 0;
        width: 0.5em;
        height: 1.5em;
        background-color: #3DB8ED;
        margin-right: 5px;
    }
    .timeline-wrapper{
        display: flex;
        flex: 1 1 auto;
    }
    .timeline-items{
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .timeline-dot{
        background-color: #dfdfdf63;
        height: 0.8rem;
        width: 0.8rem;
        border-radius: 100%;
        border: 1px solid #dfdfdf;
    }
    .timeline-connect{
        margin-top: 0.3em;
        background-color: #dfdfdf63;
        flex-grow: 1;
        height: 100%;
        width: 2px;
    }
    .timeline-label {
        color: #fafafa;
        font-size: 0.95em;
        font-weight: unset !important;
        margin-bottom: 0.5em;
    }
    .timeline-link {
        color: #b7e4f9;
        font-size: 0.85em;
        text-decoration: underline;
    }
    .timeline-content {
        margin-left: 1.5rem;
        padding-bottom: 2.6rem;
        padding-top: 0.125rem;
        width: 100%;
    }
    .timeline-update-at{
        padding-top: 0.4rem;
    }
    .attachment-border{
        padding: 0.8em;
        border: 1px solid #dfdfdf;
        border-radius: 0.25rem;
        .attachment-grid {
            display: grid;
            gap: 2rem;
            grid-template-columns: repeat(2,minmax(0,1fr));
        }
        .attachment-title {
            font-size: 16px;
            color: #333333;
        }
        .description {
            font-size: 14px !important;
            color: #6b7280;
            font-weight: unset !important;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0.25em;
        }
    }
    .card-display {
        overflow: hidden;
        transition: height 0.5s;
      }
      
    .report-box{
        background-color: #222A39;
        border-radius: 1rem;
        border: 1px solid #E5E5E5;
        color: #EEE;
        padding: 0.5em 1em;
        label{
            width: 100%;
            font-size: 1.4rem;
            font-family: 'Inter';
        }
        .open-btn {
            display: flex;
            justify-content: center;
            margin-top: 0.5em;
            margin-bottom: 0em;

            div {
                position: absolute;
                margin-top: -0.6em;
                margin-left: -0.6em;
                .normal-animation {
                    animation: keyframes-return .5s;
                    transform-origin: center;
                    transform-box: fill-box;
                }
            }

            &.--opened {
                div {
                    .rotated-animation {
                        animation: keyframes-rotate .5s;
                        transform: rotate(180deg);
                    }
                }
            }
        }
        
        @keyframes keyframes-rotate {
            0% {
                transform: rotate(0deg);
                opacity: 0;
            }
            
            100% {
                transform: rotate(-180deg);
            }
        }
        
        @keyframes keyframes-return {
            0% {
                transform: rotate(-180deg);
                opacity: 0;
            }
            
            100% {
                transform: rotate(0deg);
            }
        }
    }
}

[data-quantity] button.add {
    right: -1.5rem !important;
}

[data-quantity] input:focus {
    box-shadow: none !important;
}

[data-quantity] {
    width: 27.1rem !important;
    border-radius: 10px;
    height: 4rem !important;
    background: #d2f0ff;
    max-width: unset !important;
    box-shadow: 0px 4px 0px 0px #a2ddf7 !important;
}

[data-quantity] input {
    text-align: center;
    width: 100% !important;
    padding: 0 0 !important;
    margin: 1rem 0rem !important;
    box-shadow: none !important;
    background: unset !important;
    font-weight: normal !important;
    color: #626668 !important;

    @media (min-width: 991px) {
        width: 22rem !important;
        margin: 1rem 3rem !important;
    }
}

[data-quantity] button.sub {
    left: 2rem !important;
    background-position-x: 5.2rem !important;
}

[data-quantity] button {
    display: flex;
    justify-content: center;
    width: 3.5rem !important;
    height: 3.5rem !important;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="50"><path d="M17 25h16M67 25h16M75 17v16" fill="black" stroke="black" stroke-width="1.5px" stroke-linecap="round" stroke-linejoin="round" /></svg>') !important;
    background-size: 5rem 3rem !important;
}

[data-quantity] button.add {
    right: 2rem !important;
    background-position-x: -2.2rem !important;
}

[data-quantity] button:hover {
    box-shadow: none !important;
}

.react-pdf__Page__canvas{
    height: 100% !important;
}

.p-datepicker{
    z-index: 99999 !important;
}

.custom-tooltip {
    background-color: #f0f0f0;
    color: #fafafa !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 12px !important;  
    border-radius: 0.5em !important;
    background: #222a39 !important;
    border: none !important;
    opacity: 1 !important;
    z-index: 9999 !important;
}

.common-main-container{
    padding: 0.8em;
    .tnc-container{
        position: relative;
        ._label{
            &.--tc-title{
                color: #3db8ed;
                font-size: 1.9rem;
                font-weight: 700 !important;
                text-decoration: underline;
                line-height: 1.5em;
            }
        }
        ._text{
            &.--title{
                color: #3db8ed;
                font-size: 2.5rem;
                font-weight: 700;
                font-style: italic;
                line-height: 1.8em;
            }
            &.--subtitle{
                // color: #3db8ed;
                font-size: 1.5rem;
                font-weight: 500;
            }
            &.--t_c_title{
                margin-top: 0.5em;
                color: #3db8ed;
                font-size: 1.65rem;
                font-weight: 600 !important;
                line-height: 1.5em;
            }
            &.--t_c_content{
                font-size: 1.45rem;
                color: #231f1f;
                font-weight: 500;
            }
            &.--t_c_detail{
                font-size: 1.5rem;
                color: #231f1f;
                font-weight: 600;
            }
        }
        .t_and_c-dropdown-div{
            position: sticky;
            top: 0px;
            .p-dropdown{
              width: 100%;
              border-color: #3db8ed;
              box-shadow: 0 0 0 0.2rem #3db8ed ;
              .p-inputtext{
                font-size: 1.9rem;
                font-weight: 500;
                // color: #FBB24B;
              }
            }
           
          }
          .navLink{
            color: #3db8ed !important;
            font-size: 1.6rem !important;
            margin: 1em 0em !important; 
            cursor: pointer;
            font-weight: 600 !important;
          }
          .navLink-active{
            color: #3db8ed !important;
            font-size:2rem !important;
            cursor: pointer; 
            font-weight: 600 !important;
          }
    }
}

.post-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



   

.toggleSwitchContainer{
  padding-top: 1em;
}
.toggleSwitch{
  border-radius: 25px;
  background-color: unset;
  padding: 1px 0px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width : 991px) {
  .toggleSwitch{
    width: 100%;
  }
}
.toggle {
  margin: 0.5em;
  position: relative;
  width: auto;
  display: flex;
  border-radius: 18px;
  text-align: center;
  }
  .toggle-optionTwo {
    margin: 0.5em;
    position: relative;
    width: auto;
    display: flex;
    border-radius: 18px;
    text-align: center;
  }

  @keyframes slideIn {
    from {
      background-color: #eeeeee;
      z-index: 1000;
      transform: translateX(100%);
      opacity: 1;
    }
    to {
      background-color: #eeeeee;
      z-index: 1000;
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideOut {
    from {
      background-color: #eeeeee;
      z-index: 1000;
      transform: translateX(0);
      opacity: 1;
    }
    to {
      background-color: #eeeeee;
      z-index: 1000;
      transform: translateX(100%);
      opacity: 1;
    }
  }

  #active-toggled {
    animation: slideOut 0.5s ease-in-out forwards; 
  }
  
  #active-left-toggled {
    animation: slideIn 0.5s ease-in-out forwards;
  }

  .optionOne-white, .optionTwo-white {
    font-size: 12px;
    color: #83829a;
    width: 50%;
    margin: 0;
    font-weight: 500;
    padding: 8px 12px;
    border-radius: 18px;
    cursor: pointer;
    font-family: "inter-regular";

  }
  .switch {
    width: 50%;
    margin: 0;
    font-weight: 600;
    color: #6261be;
    padding: 8px 12px;
    border-radius: 18px;
    cursor: pointer;
  }
  .switch {
    position: absolute;
    top: 0;
    height: 100%;
    background-color: #24262D
    ;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    z-index: -1;
    transition: all 0.4s ease-in-out;
    transform: translate(0, 0);
  }

  @keyframes slideBackgroundIn {
    from {
      background-color: #eeeeee !important;
      z-index: 1001;
      box-shadow: none !important;
      color: #333333;
      opacity: 1;
    }
    to {
      background-color: #eeeeee;
      z-index: 1001;

      box-shadow: none !important;
      color: #333333;
      opacity: 1;
    }
  }

  .optionOne,
  .optionTwo,
  .switch {
    width: 50%;
    margin: 0;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 12px;
    box-shadow: none !important;
    animation: slideBackgroundIn 1s ease-in-out forwards;
    font-family: "inter-regular";
    border-radius: 18px;
    transition: none !important;
    /* z-index: -10 !important; */
  }

  .optionOne.--no-animation,
  .optionTwo.--no-animation{
    width: 50%;
    margin: 0;
    padding: 8px 12px;
    cursor: pointer;
    background-color: #eeeeee !important;
    font-size: 12px;
    box-shadow: none !important;
    animation: unset !important;
    font-family: "inter-regular";
    border-radius: 18px;
    transition: none !important;
  }

  .switch.--no-animation{
    width: 50%;
    margin: 0;
    padding: 8px 12px;
    cursor: pointer;
    background-color: transparent !important;
    font-size: 12px;
    box-shadow: none !important;
    animation: unset !important;
    font-family: "inter-regular";
    border-radius: 18px;
    transition: none !important;
  }

  .optionTwo {
    color: #333333;
  }
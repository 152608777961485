@font-face {
    font-family: "password";
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/fonts/password.ttf);
}

@font-face {
    font-family: "cabin-regular";
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/fonts/Cabin-Regular.woff); //prefer format .woff 
    font-display: swap;
}

@font-face {
    font-family: "inter-regular";
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/fonts/Inter-Regular.woff); //prefer format .woff 
    font-display: swap;
}
@keyframes slideBackground {
	0% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}

@keyframes rotateBorderAnimation {
	0% {
	  transform: rotate(0deg);
	  border-top-color: transparent;
	  border-right-color: transparent;
	  border-bottom-color: transparent;
	  border-left-color: transparent;
	}
	25% {
	  border-top-color: #3db8ed;
	}
	50% {
	  border-right-color: #3db8ed;
	}
	75% {
	  border-bottom-color: #3db8ed;
	}
	100% {
	  border-left-color: #3db8ed;
	}
}

@keyframes scaleAnimation {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    50% {
      transform: scale(1.5);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
}



  
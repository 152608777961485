@import "../abstracts/variables";

.form-label{
    font-family: "inter-regular";
    font-size: 14px !important;
    color: #eeeeee !important;
    padding: 0;
    margin: 0;
}
.form-group{
    padding: 0.5em;
    &._text{
        input{
            padding: 0.5em;
            width: 100%;
        }
        position: relative;
        align-items: center;
        .verification-button{
            width: auto;
            position: absolute;
            right: 1em;
            button{
                font-size: 1rem;
                background: rgba(67, 67, 67, 0.1);
                border-radius: 0.6em;
                padding: 0.5em 1em;    
                border: none; 
            }
        }
        .code-container{
          display: flex;
          justify-content: center;
          width: 100%;
          .code-input{
            width: 2.2em;
            text-align: center;
            margin-right: 0.5em;
            outline: none;
            background: transparent;
            border-bottom: 0.1em solid #eeeeee;
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0em;
            padding: 0.6em;
            color: #eeeeee;
            box-shadow: none;
            font-style: none !important;
            font-size: 16px;
            @media (min-width: 500px) {
              width: 3em;
              margin-right: 1em;
            }
          }
        }
        &.mdb-field {
            &.disable{
                opacity: 0.7;
                background-color: #f1f1f1;
            }
            margin: 1rem 0;
            color: #d6d6d6;
            align-items: start;
            margin-bottom: 0.1rem;
            border-radius: 0.9rem;
            border: 1px solid #EDEDED;
            ._errors{
                .form-outline {
                    .form-label {
                      color: red !important;
                    }
                    .form-control {
                        color: red;
                    }
                }
            }
            input::-webkit-date-and-time-value {
                margin-top: 0px;
                text-align: start;
                width: 100%;
            }        
            .form-outline {
                .form-label {
                    font-size: 1.2rem !important;
                    color: #5C5C5C !important;
                }
                .form-control {
                    padding: 0;
                    color: $text-color--primary !important;
                    font-size: 1.8rem;
                    margin: 0.6em 0em 0em 0em;
                }
                .form-control:disabled{
                    background-color: transparent !important;
                }
            }
            .form-notch {
            display: none !important;
            }  
        }
    }
    &._errors{
        position: absolute;
        padding: 0;
        color: #EA3943;
        font-family: 'Inter';
        font-size: 1.2rem;
        font-weight: 400;
    }
    &._errors2{
        color: #EA3943;
        font-family: 'Inter';
        font-size: 1.2rem;
        font-weight: 400;
    }
    &._select{
        select{
            padding: 0.5em;
            width: 100%;
        }
          &.gender{
            padding-bottom: 0;
            margin: 1rem 0;
            color: #d6d6d6;
            align-items: start;
            margin-bottom: 0.1rem;
            border-radius: 0.9rem;
            border: 1px solid #EDEDED;
            .form-label{
              margin: 0em 0em 0em 0.5em;
              font-size: 1rem !important;
              color: #5C5C5C !important;
            }
            select{
                padding: 0;
                font-size: 1.8rem;
                width: 100%;
                border: none;
                color: $text-color--primary;
                margin: 0.6em 0em;
                background: transparent;

            }
            select:focus{
              outline: none;
            }
            &._errors{
                .form-label{
                    color: red !important;
                  }
                  select{
                    color: red;
                  }
            }
          }
    }
    &._dropdown{
        .dropdown{
            .dropdown-toggle{
                background-color: transparent;
                color: black;
                box-shadow: none;
                border: 0.1em solid rgba(0, 0, 0, 0.675);
                padding: 0.5em;
                font-size: 1.6rem;
                width: 100%;
            }
            .dropdown-toggle::after{
                position: absolute;
                top: 1.2em;
                right: 1em;
            }
            .dropdown-menu{
                font-size: 1.6rem;
                width: 100%;
                text-align: center;
                li{
                    padding: 0.5em;
                }
                li:hover{
                    background-color: rgba(239, 239, 239, 0.998);
                    padding: 0.5em;
                }
            }
        }
    }
    &._calendar{
        .p-calendar{
            width: 100%;
            input{
                font-size: 1.6rem !important;
            }
            button{
                width: 5em;
                .pi{
                    font-size: 1.6rem !important;
                }
            }
        }
    }
    &._button{
        &.form_button{
            display: flex;
            text-align: center;
            font-family: "Inter";
            justify-content: center;
            align-items: center;
            .submit-button{
                outline: none;
                border-radius: 12px;
                background-color: rgba(61, 184, 237, 1);
                align-items: center;
                display: flex;
                justify-content: center;
            } 
            .btn-primary{
                width: auto;
                height: 44px;
                font-size: 18px !important;
                color: #fafafa;
                box-shadow: 0px 4px 0px 0px rgba(71, 151, 209, 1);
                padding: 11px 80px 11px 80px;
                text-transform: capitalize;

                @media (max-width: 991px) {
                    font-size: 16px;
                    padding: 12px 30px 12px 30px;
                }
           }
           .btn-primary:active{
                box-shadow: 0 2px #4797D1 !important;
                transform: translateY(2px);
            }
           .btn-primary:disabled{
                background: #9c9c9c;
                box-shadow: 0 5px #5f5f5f !important;
           }
        }
        &.save_button{
            text-align: center;
            margin-top: 1.5em;
            color:#5C5C5C ;
            height: 2em;
            font-size: 1.8rem;
            text-decoration: underline;
            text-transform: capitalize;
        }
    }
}

.form-check-input[type=checkbox].tc_checkbox{
    border-radius: 0.25rem;
    margin-top: 0.19em;
    margin-right: 6px;
    border: 0.1em solid rgba(214, 214, 214, 1);
    width: 17px;
    height: 17px;
}
.form-check-input[type=checkbox]::before{
    border-radius: 0.25rem;
    margin-top: 0.19em;
    margin-right: 6px;
    background: transparent !important;
    border: 0.1em solid rgba(214, 214, 214, 1);
    width: 17px;
    height: 17px;
}
.form-check-input[type=checkbox]:checked:after{
    margin-left: 0.5rem !important;
    margin-top: 1.5px !important;
}
.form-check-input[type=checkbox]:checked{
    border: 0.1em solid rgba(214, 214, 214, 1);
    background-color: transparent !important;
}

.button-group{
    font-family: 'Inter';
    &.--login-button {
        position: relative;
        display: inline-block;
        padding: 8px 20px;
        color: #fff;
        font-size: 16px;
        text-decoration: none;
        overflow: hidden;
        transition: .5s;
        letter-spacing: 3px;
    } 
    &.--login-button:hover {
        color: #fafafa !important;

        span {
            border-bottom: 0.2em solid #ebebeb;
            animation: login-animation 1.5s linear infinite;
        }
        svg{
            color: #fafafa !important;
        }

        @keyframes login-animation {
            0% {
                opacity: 1;
                width: 0%;
            }
            
            100% {
                opacity: 0.2;
                width: 85%;
            }
        } 
    }      
    &.--login-button span {
        bottom: -2%;
        position: absolute;
        display: block;
    }   

    &.--notification-button {
        padding: 0;
        height: 39.5px;
        width: 39.5px;
        font-size: 24px;
        text-align: center;
        cursor: pointer;
        outline: none;
        color: #222A39;
        background-color: #FAFAFA !important;
        border: none;
        border-radius: 0.3em;
        box-shadow: 0 5px rgba(173, 156, 156, 0.25)!important;
        .red-dot{
            background-color: rgb(244, 67, 54);
            width: 0.4em;
            height: 0.4em;
            border-radius: 50%;
            position: absolute;
            z-index: 5;
            right: 0.25em;
            top: 0.19em;
        }
        svg{
            color:#222A39
        }
        .ripple-wave{
            display: none;
        }
        &.--create-post{
            color: #ffffff !important;
            background-color: #3DB8ED !important;
            box-shadow: 0 5px  #4797D1 !important;

            padding: 8px;
            width: 67px;

            p {
                align-items: center;
                display: flex;
                justify-content: center;
                text-transform: capitalize;
                font-size: 1.4rem;
                color: #fafafa;
                svg{
                    margin-left: 0.2em;
                }
            }
        }
        &.--create-post-round{
            color: #FAFAFA !important;
            height: auto !important;
            background-color: #3DB8ED !important;
            box-shadow: 0 5px  #4797D1 !important;
            border-radius: 26px !important;
            padding: 8px;
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: center;
            text-transform: capitalize;
            font-size: 1.8rem;
      
        }
        &.--view-post{
            height: unset;
            color: #FAFAFA !important;
            background-color: #3DB8ED !important;
            box-shadow: 0 5px  #4797D1 !important;
            padding: 9px 8px;
            width: 10em;

            @media (min-width: 991px) {
                width: 240px;
                font-size: 18px;
                padding: 10px 9px;
            }

            p{
                align-items: center;
                display: flex;
                justify-content: center;
                text-transform: capitalize;
                font-size: 16px;
                svg{
                    margin-left: 0.2em;
                }
            }
            &:disabled{
                cursor: no-drop;
                background-color: #91919192 !important;
                box-shadow: 0 5px  #5a5a5a8e !important;
            }
        }
    }
    &.--notification-button:hover {
        .red-dot{
            background-color: rgb(244, 67, 54);
            width: 0.4em;
            height: 0.4em;
            border-radius: 50%;
            position: absolute;
            z-index: 5;
            right: 0.25em;
            top: 0.19em;
        }
        background-color: #FAFAFA !important;
        &.--create-post{
            background-color: #3DB8ED !important;
        }
        &.--create-post-round{
            background-color: #3DB8ED !important;
        }
        &.--view-post{
            background-color: #3DB8ED !important;
            &:disabled{
                cursor: no-drop;
                background-color: #91919192 !important;
                box-shadow: 0 5px  #5a5a5a8e !important;
            }
        }
    }
    &.--notification-button:active {
        .red-dot{
            background-color: rgb(244, 67, 54);
            width: 0.4em;
            height: 0.4em;
            border-radius: 50%;
            position: absolute;
            z-index: 5;
            right: 0.25em;
            top: 0.19em;
        }
        background-color: #FAFAFA !important;
        box-shadow: 0 2px rgba(173, 156, 156, 0.25)!important;
        transform: translateY(4px);
        // outline: 0.1em solid #EDEDED !important;
        svg{
            color: #222A39;
        }
        &.--create-post{
            background-color: #3DB8ED !important;
            box-shadow: 0 2px  #4797D1 !important;
        }
        &.--create-post-round{
            background-color: #3DB8ED !important;
            box-shadow: 0 2px  #4797D1 !important;
        }
        &.--view-post{
            background-color: #3DB8ED !important;
            box-shadow: 0 2px  #4797D1 !important;
            &:disabled{
                cursor: no-drop;
                background-color: #91919192 !important;
                box-shadow: 0 2px #5a5a5a8e !important;
            }
        }
    }
}

.p-component{
    .p-link{
        font-size: 1.6rem !important;
    }
    .p-datepicker-calendar-container {
        .p-datepicker-calendar {
          thead {
            tr {
              th {
                text-align: center;
              }
            }
          }
          font-size: small;
        }
    }
}

.rti--container{
    margin: 1em 0em;
    padding: 0.9em 1.4em  0.9em 1.4em !important;
    border-radius: 1em !important;
    border: 1px solid #EDEDED !important;
    min-height: 63.59px !important;
    .rti--input{
        font-size: 1.8rem;
        width: 100%;
    }
    .rti--input::placeholder{
        font-size: 1.2rem;
    }
    .rti--tag{
        font-size: 1.7rem;
    }
}
.rti--container:focus-within{
    padding: 0.9em 1.4em  0.9em 1.4em !important;
    .rti--tag{
        font-size: 1.5rem;
    }
}

//outside close button
.close-circle{
    position: fixed;
    display: flex;
    z-index: 1200;
    transition: all .3s;
    cursor: pointer;
    left: 6em;
    top: 10em;
    .close{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 0.1em solid #222A39;
    }
}

//tag input
.tag-div{
    .tag-badge{
        margin-left: 0.7em;
        display: inline-flex;
        align-items: center;
        font-size: 1.3rem;
        background: #53ADF0 !important;
        .remove-icon{
            cursor: pointer;
        }
    }
}

.icon-div{
    width: 15%;
    height: 2em;
    position: absolute;
    text-align: end;
    right:  0%;
    .add-icon{
        height: 2em;
        width: 2em;
        cursor: pointer;
    }
}

//post filter button
.filter-button{
    padding: 0 !important;
    height: 36px !important;
    width: 36px;
    font-size: 24px !important;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #222A39;
    background-color: #FAFAFA !important;
    border: none;
    border-radius: 0.3em !important;
    box-shadow: 0 5px rgba(173, 156, 156, 0.25)!important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active{
        background-color: #FAFAFA !important;
        box-shadow: 0 2px rgba(173, 156, 156, 0.25)!important;
        transform: translateY(4px);
    }
    label{
        color: #222A39;
        font-size: 1.4rem;
        margin-left: 0.2em;
    }
}

.pointer{
    cursor: pointer;
}

.break-all{
    word-break: break-all;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    caret-color: #333333;
    -webkit-text-fill-color: #333333;
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
}

p {
    margin-bottom: 0 !important;
}

h1, h2, h3 {
    margin-bottom: 0 !important;
    font-weight: unset !important;
}